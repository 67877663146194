import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { agencyActions as a } from "../../../../actions/agencyAction";
import { constants as c } from "../../../../constants";
import { formatNumber , formatPrice } from "../../../../helper";
import moment from "moment"
export default function InfoTab(props) {
  const dispatch = useDispatch();
  const with_draw = useSelector((state) => state.agency.with_draw);

  useEffect(() => {
    if (with_draw.status === c.LOADING) {
      dispatch(a.getAgencyWithDraw());
    }
  });




  return (
    <React.Fragment>
      <div class="statistic-tab" style={{ width: "100%", display: "unset" }}>
   
        <div className="statistic-tab">
          <div className="receipt-tab" style={{ width: "100%" }}>
            <table>
              <thead>
                <tr>
                  <th className="order-id">Mã giao dịch</th>
                  <th className="date">Thời gian rút</th>
                  <th className="n-product">Tên tài khoản</th>
                  <th className="total">Số tài khoản</th>
                  <th className="status">Ngân hàng</th>
                  <th className="status">Số tiền rut</th>
                  <th className="status">Trạng thái</th>
                </tr>
              </thead>
              <tbody>
                {with_draw.status == c.SUCCESS &&
                  with_draw.data?.length > 0 &&
                  with_draw.data.map((v, i) => {
                    return (
                      <tr>
                        <td className="order-id" style={{ minWidth: "160px" }}>
                          {v.code}
                        </td>
                        <td className="date">{moment(v.created_at).format("YYYY-MM-DD")}</td>
                        <td className="n-product">{v.bank_account_name}</td>
                        <td className="total"> {v.bank_number}</td>
                        <td className="total"> {v.bank_name}</td>
                        <td className="total"> {formatPrice(v.money)}</td>

                        <td className="status" style={{color : v.status == 0 ? "blue" : v.status == 1 ? "red" : "green"}}>
                            {v.status == 0 ? "Chờ duyệt" : v.status == 1 ? "Hủy" : "Đã duyệt"}
                          <br />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div />
          </div>
        </div>{" "}
      </div>
    </React.Fragment>
  );
}
