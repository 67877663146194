import { useSelector } from "react-redux";
import { formatDmyHms, formatPrice , formatHmDmy } from "../../../helper";
import Paginate from "../../../components/Paginate";
export default function BalanceTab(props) {
  const appTheme = useSelector(state => state.app.appTheme);
  return (
    <div className="balance-tab">
      <table>
        <thead>
          <tr>
            <th>Ngày</th>
            <th>Thay đổi</th>
            <th className="balance">Quỹ đại lý</th>

            <th className="detail">Nội dung</th>
            <th className="mobile"></th>
          </tr>
        </thead>
        <tbody>
          {
            props.history.data.map((v, i) =>
              <tr key={i}>
                <td className="date">
                {formatHmDmy(v.created_at)}
                </td>
                <td>
                <i class={`fas fa-arrow-circle-${v.money > 0 ? "up" : "down"}`} style={{color : v.money > 0 ? "green" : "red"}} aria-hidden="true"></i>
                 {" "} {formatPrice(v.money)}
             
                </td>
                <td className="balance">
                
                {" "} {formatPrice(v.current_money)}
               
                </td>
                <td className="detail">{v.type_name}</td>
        
                {/* <td className="mobile">
                  <button style={{ background: appTheme.color_main_1 }}>Chi tiết</button>
                </td> */}
              </tr>
            )
          }
        </tbody>
      </table>
      <Paginate totalPage={props.history.last_page} currentPage={props.history.current_page} />
    </div>
  )
}