import { constants as c } from "../constants";
const initialState = {
  list: {
    status: c.LOADING,
    data: [],
  },
  voucherPopup : {}
};
export function voucher(state = initialState, action) {
  switch (action.type) {
    case c.GET_ALL_VOUCHERS_SUCCESS:
      return {
        ...state,
        list: {
          data: [...action.data],
          status: c.SUCCESS,
        },
      };
    case c.GET_ALL_VOUCHERS_FAILURE:
      return {
        ...state,
        list: {
          data: [],
          status: c.FAILURE,
        },
      };
      case c.GET_VOUCHERS_POPUP:
        return {
          ...state,
          voucherPopup: {
            ...action.data,
            status : c.SUCCESS
          }
        };

        case c.RESET_VOUCHERS_POPUP:
          return {
            ...state,
            voucherPopup: {
              status : c.RESET_VOUCHERS_POPUP
            }
          };
    default:
      return state;
  }
}
