import { formatNoD, formatPrice } from "../../../helper";
import { useSelector, useDispatch } from "react-redux";
import InfoPopup from "./InfoPopup";

import { collaboratorActions as a } from "../../../actions/collaboratorActions";
import React, { useEffect, useState } from "react";
import RechargeForm from "./ChildPaidTab/RechargeForm";
import WithDrawForm from "./ChildPaidTab/WithDrawForm";
import { agencyActions as b } from "../../../actions/agencyAction";
import { appActions as appActions } from "../../../actions/appActions";
import { constants as c } from "../../../constants";

export default function InfoTab(props) {
  const { balance, deposit } = props.account;
  const { setCurrentTab, setCurrentStatisticTab, profile, agency_type } = props;

  console.log(agency_type);
  const {
    first_and_last_name,
    bank,
    branch,
    account_name,
    account_number,
    back_card,
    front_card,
    cmnd,
    issued_by,
  } = props.account;

  const {
    payment_limit,
    has_payment_request,
    payment_1_of_month,
    payment_16_of_month,
  } = props.info;
  const appTheme = useSelector((state) => state.app.appTheme);
  const [customClass, setcustomClass] = useState("");
  const [payment_auto, setPaymentAuto] = useState(false);
  const account = useSelector((state) => state.collaborator.account);
  const [formClass, setFormClass] = useState("hidden");
  const [currentForm, setCurrentForm] = useState("CREATE");
  const [showPaid, setShowPaid] = useState(false);
  const [tabSelect, setTabSelect] = useState(true);

  const [tabPayment, setTabPayment] = useState(false);

  const [data, setData] = useState({ name: "", price: "" });

  function handleCloseForm() {
    setFormClass("");
  }
  const dispatch = useDispatch();

  function handleShowPopup() {
    setcustomClass("center");
  }
  function handleClosePopup() {
    setcustomClass("");
  }

  function getContentPayment() {
    if (payment_1_of_month == true && payment_16_of_month == true) {
      return "ngày 1 và ngày 16";
    }
    if (payment_1_of_month == true) {
      return "ngày 1";
    }
    if (payment_16_of_month == true) {
      return "ngày 16";
    }
    return "";
  }

  function updatePaymentAuto(v) {
    setPaymentAuto(v);
    var data = account;
    data.payment_auto = v;
    dispatch(a.updateInfo(data));
  }

  function handleClose() {
    setFormClass("hidden");
  }

  function handleShowForm(type) {
    setCurrentForm(type);
    setFormClass("active-modal");
  }

  function handlePaid(item) {
    setShowPaid(true);
    setData(item);
  }
  function handleSubmit() {
    var {price} = data
 
      dispatch(
        b.postAgencyRechage({
          money: data.price,
        })
      );
      dispatch(appActions.changePopup(c.MESSAGE_POPUP));
      props.setCurrentTab("paid");


      props.handleClose();
    
  }

  var {price} = data

  return (
    <React.Fragment>
      <div className={props.formClass}>
        <div
          className="modal center"
          style={{
            position: "relative",
            inset: "0px",
            border: "none",
            background: "rgb(0 0 0 / 0%)",
            overflow: "unset",
            borderRadius: "20px",
            outline: "none",
            padding: "0px",
            width: "900px",
            height: "620px",
            margin: "0px auto",
            marginTop: "14px",
          }}
        >
      
          <div
            style={{
              width: "100%",
              position : "relative"
            }}
          >
                <button onClick={props.handleClose}>
            <i
              className="fas fa-angle-left"
              style={{
                fontSize: 25,
                cursor: "pointer",
                position: "absolute",
                top: "20px",
                left: "10px",
                "z-index": "999",
              }}
            ></i>
          </button>
            <div className="regis-popup">
              <div class="container">
                <ul
                  class="progressbar"
                  style={{
                    display: "flex",
                    "justify-content": "center",
                  }}
                >
                  <li className={tabSelect == true ? "active" : ""}>Chọn gói</li>
                  <li className={tabPayment == true ? "active" : ""}>Thanh toán</li>
                </ul>
              </div>
              {showPaid === true && (
                <div className="payment-tab" style={{ marginTop: "15px" }}>
                  <div
                    className="payment-info"
                    style={{ width: "100%"}}
                  >
                    <div className="top">
                      <img style={{marginLeft : "10px"}} src="/img/wallet.png" alt="" />
                      <span>Nạp tiền đại lý</span>
                    </div>

                    <div className="content content-agency-deduct">
                      <div
                        className="row"
                        style={{
                          marginBottom: "5px",
                          fontStyle: "italic",
                          color: appTheme.color_main_1,
                        }}
                      >
                        ***Vui lòng chuyển khoản đến tài khoản ngân hàng sau và
                        nhấn vào nút đã chuyển tiền để được nạp tiền vào tài
                        khoản
                      </div>

                      <div className="row">
                        <label>Số tiền cần chuyển: {"  "}</label>
                        <span>
                          &nbsp; {formatPrice(price)}
                        </span>
                      </div>

                      <div className="row">
                        <label>Ngân hàng: {"  "}</label>
                        <span>
                          &nbsp;Ngân hàng Quân Đội (MBBank)
                        </span>
                      </div>
                      <div className="row">
                        <label>Chi nhánh: {"  "}</label>
                        <span>
                          &nbsp;THĂNG LONG
                        </span>
                      </div>
                      <div className="row">
                        <label>Số tài khoản: {"  "}</label>
                        <span>&nbsp;818187777</span>
                      </div>
                      <div className="row">
                        <label>Tên thụ hưởng: {"  "}</label>
                        <span>&nbsp;CÔNG TY CỔ PHẦN TẬP ĐOÀN BEGROUP</span>
                      </div>
                      <div className="row">
                        <label>** Nội dung chuyển khoản: {"  "}</label>
                        <span style={{ fontWeight: "500" }}>
                          &nbsp;BECARE{profile.phone_number}
                        </span>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div style={{ display: "flex" }}>
                            <button
                              style={{
                                color: "gray",
                                margin: "5px 0px 0px",
                                marginRight: "10px",
                              }}
                              className="button-cancel"
                              onClick={() => {
                                setShowPaid(false);
                                setTabSelect(true)
                                setTabPayment(false);
                             
                                    

                                setData({ name: "", price: "" });
                              }}
                            >
                              Trở về
                            </button>
                            <button
                              onClick={() => handleSubmit()}
                              style={{
                                background: appTheme.color_main_1,
                                margin: "5px 0px 0px 0px",
                                cursor: "pointer",
                              }}
                            >
                              Nạp tiền
                            </button>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>

                  <InfoPopup
                    customClass={customClass}
                    info={props.account}
                    onClose={handleClosePopup}
                  />
                </div>
              )}
              {showPaid === false && (
                <div
                  className="payment-tab"
                  style={{ marginTop: "15px", marginLeft: "10px" }}
                >
                  <div className="payment-info" style={{ width: "100%" }}>
                    <div className="top">
                      <img src="/img/wallet.png" style={{marginLeft : "10px"}} alt="" />
                      <span>Chọn gói</span>
                    </div>

                    <div className="content content-agency-deduct">
                      <div className="list-item">
                        {agency_type.data?.map((v) => {
                          return (
                            <div className="item">
                              <p
                                style={{
                                  "font-size": "18px",
                                  "font-weight": "600",
                                  "padding-bottom": "10px",
                                  color: "#888585",
                                }}
                              >
                                {" "}
                                {v.name}
                              </p>
                              <p style={{ fontSize: "15px" }}>
                                {formatPrice(v.plan)}
                              </p>

                              <p>Chiết khấu {v.commission_percent}%</p>
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "0px",
                                  width: "100%",
                                  margin: "auto",
                                }}
                              >
                                <button
                                  onClick={() =>
                                    {

                                      setTabSelect(false);
                                    setTabPayment(true);
                                    handlePaid({ name: v.name, price: v.plan })
                                    }
                                  }
                                  style={{ cursor: "pointer" }}
                                  className="button-submit"
                                >
                                  Chọn gói
                                </button>{" "}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ textAlign: "end", marginTop: "5px" }}>
                <button
                  className="button-cancel"
                  onClick={props.handleClose}
                >
                  Hủy bỏ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
