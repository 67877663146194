import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header_10 from "./Header/Header10/index";


import { constants as c } from "../constants";
import { appActions } from "../actions/appActions";
import { propTypes } from "react-spinkit";
// import Header_Home from "./Header/headerHome";
import { categoryActions } from "../actions/categoryActions";


export default function Header(props) {
  const dispatch = useDispatch();
  const headerType = useSelector((state) => state.app.appTheme.header_type);  
  const appTheme = useSelector((state) => state.app.appTheme);
  const [loadFont, setLoadFont] = useState(false);


  const bannerAds = useSelector((state) => state.app.bannerAds);

  const category = useSelector((state) => state.category);

  useEffect(() => {
    if (category.status === c.LOADING)
      dispatch(categoryActions.getCategories());
    if (bannerAds.status === c.LOADING) dispatch(appActions.getBannerAds());
  }, []);



  function renderHeader(headerType) {
    return <Header_10 />;

  } 
  
  return <React.Fragment>{renderHeader(headerType)}</React.Fragment>;
}