import { constants as c } from "../constants";
import { cartServices as s } from "../services/cartServices";
import { toast } from "react-toastify";
import { userActions } from "../actions/userActions";
function addCart(product, willShowPopup, isBuyNow) {
  return (dispatch) => {
    s.addCart(product).then((res) => {
      if (res.code === 200) {
        localStorage.setItem("cartInfo", JSON.stringify(res.data));
        dispatch(success(res.data));
        if (product.quantity) {
          if (willShowPopup) {
            toast.success(" Đã thêm vào giỏ hàng", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          dispatch({
            type: c.CHANGE_POPUP,
            popupType: c.AUTOHIDE_POPUP,
            messageInfo: "Sản phẩm đã hết hàng",
          });
        }

        if (isBuyNow == true) {
          window.location.href = "/gio-hang";
        }
      } else {
        dispatch(failure());
        dispatch({
          type: c.CHANGE_POPUP,
          popupType: c.AUTOHIDE_POPUP,
          messageInfo: "Có lỗi xảy ra vui lòng thử lại sau !",
        });
      }
      dispatch(userActions.getUserBadges());
    });
  };
  function success(cartInfo) {
    return { type: c.ADD_CART_SUCCESS, cartInfo };
  }
  function failure() {
    return { type: c.ADD_CART_FAILURE };
  }
}
function changeNumberInCart(product, code_voucher) {
  return (dispatch) => {
    s.changeNumberInCart(product).then((res) => {
      if (res.code === 200) {
        dispatch(userActions.getUserBadges());

        localStorage.setItem("cartInfo", JSON.stringify(res.data));
        if (product.quantity === 0) {
          dispatch({
            type: c.CHANGE_POPUP,
            popupType: c.AUTOHIDE_POPUP,
            messageInfo: "Xóa sản phẩm khỏi giỏ hàng thành công !",
          });
        }
        // dispatch(userActions.getUserBadges());
        dispatch(getCartInfo(code_voucher));
      } else {
        dispatch(failure());
        dispatch({
          type: c.CHANGE_POPUP,
          popupType: c.AUTOHIDE_POPUP,
          messageInfo: "Có lỗi xảy ra vui lòng thử lại sau !",
        });
      }
    });
  };
  function success(cartInfo) {
    return { type: c.CHANGE_NUMBER_SUCCESS, cartInfo };
  }
  function failure() {
    return { type: c.ADD_CART_FAILURE };
  }
}
function purchase(order_code) {
  return (dispatch) => {
    s.purchase(order_code).then((res) => {
      if (res.code === 200) {
        window.location.reload();
      } else {
      }
    });
  };
}

function getCartInfo(code_voucher) {
  return (dispatch) => {
    s.getCartInfo(code_voucher).then((res) => {
      if (res.code === 200) {
        localStorage.setItem("cartInfo", JSON.stringify(res.data));
        if (res.data?.is_agency_import) {
          localStorage.setItem(
            "is_agency_import",
            JSON.stringify(res.data?.is_agency_import)
          );
        }
        dispatch(success(res.data));
      } else {
        if (
          res.msg_code === "NOT_ENOUGH_USE_VOUCHER" ||
          res.msg_code === "NO_VOUCHER_EXISTS"
        ) {
          localStorage.removeItem("code_voucher");
          dispatch(success(res.data));

          return;
        }

        dispatch(failure());
      }
    });
  };
  function success(cartInfo) {
    return {
      type: c.GET_CART_SUCCESS,
      cartInfo,
    };
  }
  function failure() {
    return { type: c.GET_CART_FAILURE };
  }
}

function getShipmentFee(idAddress) {
  return (dispatch) => {
    if (idAddress == null) return;
    s.getShipmentFee(idAddress).then((res) => {
      if (res.code === 200) {
        dispatch(success(res.data.data));
      } else {
        dispatch(failure());
      }
    });
  };
  function success(shipmentFee) {
    return {
      type: c.GET_SHIPMENT_FEE_SUCCESS,
      shipmentFee,
    };
  }
  function failure() {
    return { type: c.GET_SHIPMENT_FEE_FAILURE };
  }
}
function getPaymentMethods() {
  return (dispatch) => {
    s.getPaymentMethods().then((res) => {
      if (res.code === 200) {
        dispatch(success(res.data));
      } else {
        dispatch(failure());
      }
    });
  };
  function success(paymentMethod) {
    return {
      type: c.GET_PAYMENT_METHODS_SUCCESS,
      paymentMethod,
    };
  }
  function failure() {
    return { type: c.GET_PAYMENT_METHODS_FAILURE };
  }
}
function order(orderInfo) {
  return (dispatch) => {
    s.order(orderInfo).then((res) => {
      if (res.code === 201) {
        window.localStorage.removeItem("cartInfo");
        window.localStorage.removeItem("code_voucher");
        window.localStorage.removeItem("total_shipping_fee");
        window.localStorage.removeItem("is_agency_import");

        dispatch(success());
        dispatch({
          type: c.CHANGE_POPUP,
          popupType: c.ORDER_POPUP,
          orderPopupTitle: {
            title: "Đặt hàng thành công !",
            subTitle:
              "Bạn đã đặt hàng thành công vui lòng đợi xác nhận từ cửa hàng.",
          },
          paymentMethod: {
            payment_method_name: res.data.payment_method_name,
            payment_partner_name: res.data.payment_partner_name,
            payment_partner_id: res.data.payment_partner_id,
            payment_method_id: res.data.payment_method_id,
            order_code: res.data.order_code,
            orderInfo: res.data,
          },
        });
      } else {
        dispatch(failure(res.msg));
      }
    });
  };
  function success() {
    return {
      type: c.ORDER_SUCCESS,
    };
  }
  function failure(message) {
    return {
      type: c.ORDER_FAILURE,
      message,
    };
  }
}
function changePaymentMethod(info, orderInfo) {
  return (dispatch) => {
    s.changePaymentMethod(info).then((res) => {
      if (res.code === 201 || res.code === 200) {
        dispatch(success());
        dispatch({
          type: c.CHANGE_POPUP,
          popupType: c.ORDER_POPUP,
          orderPopupTitle: {
            title: "Cập nhật thông tin thanh toán thành công !",
            subTitle:
              "Bạn đã cập nhật thông tin thanh toán thành công vui lòng đợi xác nhận từ cửa hàng.",
          },
          paymentMethod: {
            payment_method_name: info.paymentMethodName,
            payment_method_id: info.paymentMethodId,
            payment_partner_id: info.paymentPartnerId,
            payment_partner_name: info.paymentPartnerName,
            order_code: info.orderCode,
            orderInfo: orderInfo || {},
          },
        });
      } else {
        dispatch(failure(res.msg));
      }
    });
  };
  function success() {
    return {
      type: c.CHANGE_PAYMENT_METHOD_SUCCESS,
    };
  }
  function failure(message) {
    return {
      type: c.CHANGE_PAYMENT_METHOD_FAILURE,
      message,
    };
  }
}
function getOrdersList(query) {
  return (dispatch) => {
    s.getOrdersList(query).then((res) => {
      if (res.code === 200) {
        dispatch(success(res.data));
      } else {
        dispatch(failure(res.code, res.msg));
      }
    });
  };
  function success(ordersList) {
    return {
      type: c.GET_ORDERS_LIST_SUCCESS,
      ordersList,
    };
  }
  function failure(code, message) {
    return {
      type: c.GET_ORDERS_LIST_FAILURE,
      code,
      message,
    };
  }
}
function getOrdersListDeduct(query) {
  return (dispatch) => {
    s.getOrdersListDeduct(query).then((res) => {
      if (res.code === 200) {
        dispatch(success(res.data));
      } else {
        dispatch(failure(res.code, res.msg));
      }
    });
  };
  function success(ordersListDeduct) {
    return {
      type: c.GET_ORDERS_LIST_DEDUCT_SUCCESS,
      ordersListDeduct,
    };
  }
  function failure(code, message) {
    return {
      type: c.GET_ORDERS_LIST_DEDUCT_FAILURE,
      code,
      message,
    };
  }
}

function getOrderInfo(orderCode) {
  return (dispatch) => {
    s.getOrderInfo(orderCode).then((res) => {
      if (res.code === 200) {
        dispatch(success(res.data));
      } else {
        dispatch(failure(res.code, res.msg));
      }
    });
  };
  function success(orderInfo) {
    return {
      type: c.GET_ORDER_INFO_SUCCESS,
      orderInfo,
    };
  }
  function failure(code, message) {
    return {
      type: c.GET_ORDER_INFO_FAILURE,
      code,
      message,
    };
  }
}
function cancelOrder(info) {
  return (dispatch) => {
    s.cancelOrder(info).then((res) => {
      if (res.code === 200) {
        dispatch(success());
        window.location.reload();
      } else {
        dispatch(failure(res.code, res.msg));
        dispatch({
          type: c.CHANGE_POPUP,
          popupType: c.AUTOHIDE_POPUP,
          messageInfo: res.msg,
        });
      }
    });
  };
  function success() {
    return { type: c.CANCEL_ORDER_SUCCESS };
  }
  function failure(code, message) {
    return { type: c.CANCEL_ORDER_FAILURE, code, message };
  }
}
function applyDiscount(info, type, setVoucher) {
  const msg = {
    code_voucher: "Áp dụng voucher thành công !",
    is_use_points: "Sử dụng xu thành công !",
    is_use_balance_collaborator: "Sử dụng số dư CTV thành công !",
    is_agency_import: "Áp dụng cho đại lý thành công !",
  };
  return (dispatch) => {
    s.applyDiscount(info).then((res) => {
      if (res.code === 200) {
        localStorage.setItem("cartInfo", JSON.stringify(res.data));

        if (info.total_shipping_fee) {
          localStorage.setItem(
            "total_shipping_fee",
            JSON.stringify(info.total_shipping_fee)
          );
        }
        if (info.is_agency_import) {
          localStorage.setItem(
            "is_agency_import",
            JSON.stringify(info.is_agency_import)
          );
        }
        if (info.code_voucher) {
          if(setVoucher)
          setVoucher(info.code_voucher);
          localStorage.setItem(
            "code_voucher",
            JSON.stringify(info.code_voucher)
          );
        }
        // dispatch({type: c.RESET_ORDER_INFO_STATUS})
        dispatch(success(res.data));
        dispatch({ type: c.VOUCHER_SUCCESS });
        if (info[type])
          dispatch({
            type: c.CHANGE_POPUP,
            popupType: c.AUTOHIDE_POPUP,
            messageInfo: msg[type],
          });
      } else {
        dispatch(failure(res.code, res.msg));
        dispatch({
          type: c.CHANGE_POPUP,
          popupType: c.AUTOHIDE_POPUP,
          messageInfo: res.msg,
        });
        dispatch({ type: c.VOUCHER_FAILURE });
      }
    });
  };
  function success(cartInfo) {
    return {
      type: c.APPLY_VOUCHER_SUCCESS,
      cartInfo,
    };
  }
  function failure(code, message) {
    return { type: c.APPLY_VOUCHER_FAILURE, code, message };
  }
}
function applyAddress(info) {
  const msg = {
    change_address: "Đã thay đổi địa chỉ !",
  };
  return (dispatch) => {
    s.applyDiscount(info).then((res) => {
      if (res.code === 200) {
        localStorage.setItem("cartInfo", JSON.stringify(res.data));
        dispatch({
          type: c.CHANGE_POPUP,
          popupType: c.AUTOHIDE_POPUP,
          messageInfo: "Đã chọn",
        });
      }
    });
  };
  function success(cartInfo) {
    return {
      type: c.APPLY_VOUCHER_SUCCESS,
      cartInfo,
    };
  }
  function failure(code, message) {
    return { type: c.APPLY_VOUCHER_FAILURE, code, message };
  }
}
export const cartActions = {
  order,
  addCart,
  getCartInfo,
  getShipmentFee,
  getPaymentMethods,
  changeNumberInCart,
  changePaymentMethod,
  getOrdersList,
  getOrderInfo,
  applyDiscount,
  cancelOrder,
  applyAddress,
  purchase,
};
