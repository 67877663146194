import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { appActions } from "../../../actions/appActions";
import { userActions } from "../../../actions/userActions";
import { constants as c } from "../../../constants";
import { validURL } from "../../../helper";
import HotlineContact from "../../HotlineContact/HotlineContact5";
import { Link } from "react-router-dom";
import "./style.css";
export default function Footer3() {
  const dispatch = useDispatch();

  const [phone, setPhone] = useState("");
  const tokenInfo = useSelector((state) => state.user.tokenInfo);
  const cartInfo = useSelector((state) => state.cart.cartInfo);
  const appTheme = useSelector((state) => state.app.appTheme);
  const infoStore = useSelector((state) => state.app.infoStore);
  const badges = useSelector((state) => state.user.badges);

  const cartNumber = cartInfo ? (cartInfo.line_items ?? []).length : 0;
  function handlePhoneChange(e) {
    setPhone(e.target.value);
  }
  function handlePhoneCheck() {
    if (tokenInfo || phone.length < 7) return;
    dispatch(userActions.accountCheck({ email: null, phone_number: phone }));
  }
  function handleAccountClick(e) {
    if (!tokenInfo) {
      e.preventDefault();
      dispatch(appActions.changePopup(c.PHONE_POPUP));
    }
  }
  function handlePostClick(e, id) {
    if (!id) e.preventDefault();
  }
  function handlePolicyClick(id) {
    if (id) window.location.href = `/tin-tuc/${id}`;
  }
  function handleShowPhonePopup() {
    dispatch(appActions.changePopup(c.PHONE_POPUP));
  }
  function handleLogout() {
    dispatch(userActions.accountLogout());
  }
  function checkToken(e) {
    if (!tokenInfo) {
      e.preventDefault();
      handleShowPhonePopup();
    }
  }
  console.log(appTheme);
  return (
    <React.Fragment>
      <HotlineContact />

      <div className="header-10 home-page-10 footer7">
        <footer
          className="footer bg-white"
          style={{ footerOverlay: "#f6f6f6" }}
        >
          <div className="mid-footer">
            <div
              className="container"
              style={{
                // "max-width": "1200px",
                margin: "auto",
              }}
            >
              <div className="row">
                <div className="col-xs-12 col-md-6 col-xl-4  footer-1">
                  <Link to="/" className="logo-wrapper mb-3 d-block">
                    <img
                      style={{
                        width: "200px",
                        height: "54px",
                        objectFit: "contain",
                      }}
                      className="img-fluid"
                      src="/img/logo.png"
                      alt="BECARE SKIN"
                      width={248}
                      height={53}
                    />
                  </Link>

                  <div className="single-contact">
                    <i className="fa fa-map-marker-alt" />
                    <div className="content">
                      Địa chỉ: <span> {appTheme.contact_address}</span>
                    </div>
                  </div>
                  <div className="single-contact">
                    <i className="fa fa-mobile-alt" />
                    <div className="content">
                      Số điện thoại:{" "}
                      <a
                        className="link"
                        href={`tel:${appTheme.contact_phone_number}`}
                      >
                        {" "}
                        {appTheme.contact_phone_number}
                      </a>
                    </div>
                  </div>
                  <div className="single-contact">
                    <i className="fa fa-envelope" />
                    <div className="content">
                      Email:
                      <a
                        className="link"
                        href={`mailto:${appTheme.contact_email}`}
                      >
                        {" "}
                        {appTheme.contact_email}
                      </a>
                    </div>
                  </div>
                  {/* <div className="single-contact">
                  <small>Becareskin.com nhận đặt hàng trực tuyến và giao hàng tận nơi. KHÔNG hỗ trợ đặt mua và nhận hàng trực tiếp tại văn phòng cũng như tất cả kho hàng Becareskin trên toàn quốc.</small>
                </div> */}
                  <div className="social-footer">
                    <h4 className="title-menu">Theo dõi chúng tôi </h4>
                    <ul className="follow_option d-flex flex-wrap align-items-center p-0 list-unstyled">
                      <div className="icon-footer" style={{ opacity: 1 }}>
                        {appTheme.contact_fanpage == null ||
                        appTheme.contact_fanpage === ""
                       ? (
                          ""
                        ) : (
                          <button style={{ background: "transparent" }}>
                            <a target="_blank" href={appTheme.contact_fanpage}>
                              <img
                                src="/img/facebook.png"
                                alt=""
                                style={{ height: "28px" }}
                              />
                            </a>
                          </button>
                        )}
                        {appTheme.id_zalo == null ||
                        appTheme.id_zalo === "" ||
                        appTheme.is_show_icon_zalo === false ? (
                          ""
                        ) : (
                          <button
                            style={{ padding: 0, background: "transparent" }}
                          >
                            <a
                              target="_blank"
                              href={"https://zalo.me/" + appTheme.id_zalo}
                            >
                              <img
                                src="/img/zalo.png"
                                alt=""
                                style={{ height: "28px" }}
                              />
                            </a>
                          </button>
                        )}

                        {appTheme.id_youtube == null ||
                        appTheme.id_youtube === "" ||
                        appTheme.is_show_icon_youtube === false ? (
                          ""
                        ) : (
                          <button style={{ background: "transparent" }}>
                            <a
                              target="_blank"
                              href={
                                "https://www.youtube.com/channel/" +
                                  appTheme.id_youtube ?? ""
                              }
                            >
                              {" "}
                              <img
                                src="/img/youtube.png"
                                alt=""
                                style={{ height: "30px" }}
                              />
                            </a>
                          </button>
                        )}
                        {appTheme.id_tiktok == null ||
                        appTheme.id_tiktok === "" ||
                        appTheme.is_show_icon_tiktok === false ? (
                          ""
                        ) : (
                          <button style={{ background: "transparent" }}>
                            <a
                              target="_blank"
                              href={
                                "https://www.tiktok.com/@" +
                                  appTheme.id_tiktok ?? ""
                              }
                            >
                              {" "}
                              <img
                                src="/img/tiktok.png"
                                alt=""
                                style={{ height: "30px", borderRadius: "3px" }}
                              />
                            </a>
                          </button>
                        )}
                      </div>
                    </ul>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-2 " style={{zIndex : 1}}>
                  <h4 className="title-menu clicked">CHÍNH SÁCH </h4>
                  <ul className="list-menu ">
                    <li className="li_menu">
                      {" "}
                      <a
                      href="/tin-tuc/dieu-khoan-su-dung-6"
                        className="link"
                        // onClick={() =>
                        //   handlePolicyClick(appTheme.post_id_terms)
                        // }
                      >
                        Điều khoản sử dụng{" "}
                      </a>
                    </li>
                    <li className="li_menu">
                      {" "}
                      <a
                        className="link"
                        // onClick={() =>
                        //   handlePolicyClick(appTheme.post_id_privacy_policy)
                        // }
                        href="/tin-tuc/chinh-sach-bao-mat-4"

                      >
                        Chính sách bảo mật{" "}
                      </a>
                    </li>
                    <li className="li_menu">
                      {" "}
                      <a
                        className="link"
                        // onClick={() =>
                        //   handlePolicyClick(appTheme.post_id_support_policy)
                        // }
                        href="/tin-tuc/chinh-sach-thanh-toan-5"

                      >
                        Chính sách thanh toán{" "}
                      </a>
                    </li>
                    <li className="li_menu">
                      {" "}
                      <a
                        className="link"
                        // onClick={() =>
                        //   handlePolicyClick(appTheme.post_id_return_policy)
                        // }
                        href="/tin-tuc/chinh-sach-doi-tra-7"

                      >
                        Chính sách đổi trả{" "}
                      </a>
                    </li>
                    <li className="li_menu">
                      {" "}
                      <a
                        className="link"
                        // onClick={() =>
                        //   handlePolicyClick(appTheme.post_id_return_policy)
                        // }
                        href="/tin-tuc/chinh-sach-cong-tac-vien-6"

                      >
                        Chính sách Cộng Tác Viên{" "}
                      </a>
                    </li>
                    <li className="li_menu">
                      {" "}
                      <a
                        className="link"
                        // onClick={() =>
                        //   handlePolicyClick(appTheme.post_id_return_policy)
                        // }
                        href="/tin-tuc/chinh-sach-dai-ly-6"

                      >
                        Chính sách Đại Lý{" "}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-2 " style={{zIndex : 1}}>
                  <h4 className="title-menu clicked">HỖ TRỢ </h4>
                  <ul className="list-menu ">
                    <li className="li_menu">
                      {" "}
                      <a
                        className="link"
                        href={
                          appTheme.post_id_about
                            ? `/tin-tuc/${appTheme.post_id_about}`
                            : "/#"
                        }
                        onClick={(e) =>
                          handlePostClick(e, appTheme.post_id_about)
                        }
                      >
                        Giới thiệu{" "}
                      </a>
                    </li>
                    <li className="li_menu">
                      {" "}
                      <a
                        className="link"
                        href={
                          appTheme.post_id_terms
                            ? `/tin-tuc/${appTheme.post_id_terms}`
                            : "/#"
                        }
                        onClick={(e) =>
                          handlePostClick(e, appTheme.post_id_terms)
                        }
                      >
                        Hướng dẫn sử dụng
                      </a>
                    </li>
                    <li className="li_menu">
                      {" "}
                      <a
                        className="link"
                        href={
                          appTheme.post_id_help
                            ? `/tin-tuc/${appTheme.post_id_help}`
                            : "/#"
                        }
                        onClick={(e) =>
                          handlePostClick(e, appTheme.post_id_help)
                        }
                      >
                        Cộng đồng
                      </a>
                    </li>
                    <li className="li_menu">
                      {" "}
                      <a
                        className="link"
                        href={
                          appTheme.post_id_help
                            ? `/tin-tuc/${appTheme.post_id_help}`
                            : "/#"
                        }
                        onClick={(e) =>
                          handlePostClick(e, appTheme.post_id_help)
                        }
                      >
                        Khởi nghiệp cùng BECARE
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-4 " style={{zIndex : 1}}>
                  <div className="ModuleContent">
                    <div className="footer-item">
                      <div className="footer-title">Hỗ trợ thanh toán</div>
                      <img
                        style={{ width: "285px", margin: "unset" }}
                        alt="#"
                        src="/img/hinhthucthanhtoan.png"
                      />
                    </div>
                    <div className="footer-item">
                      <div className="footer-title">TẢI APP BECARE</div>
                      <div
                        className="row a"
                        style={{
                          margin: "10px auto",

                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            paddingRight: 10,
                          }}
                        >
              
                              <a target={"_blank"} href="https://play.google.com/store/apps/details?id=com.becare.customer">
                                <img
                                  src="/img/play.png"
                                  alt=""
                                  style={{ height: "40px" }}
                                />
                              </a>
                            
                        </div>
                        <div
                          style={{
                            paddingRight: 10,
                          }}
                        >
                   
                              <a target={"_blank"} href="https://apps.apple.com/vn/app/becare-tr%E1%BB%8Dn-ni%E1%BB%81m-tin/id1642672552?l=vi">
                                <img
                                  src="/img/app.png"
                                  alt=""
                                  style={{ height: "40px" }}
                                />
                              </a>
                            
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-footer-bottom copyright clearfix py-2">
            <div className="container">
              <div style={{textAlign : "center",
                  "padding-bottom": "10px",
                  "font-size": "15px",
            }}>
             CÔNG TY CỔ PHẦN TẬP ĐOÀN BEGROUP. Giấy chứng nhận ĐKKD số 0110077781, cấp ngày 29/07/2022, tại Sở kế hoạch đầu tư Tp Hà Nội.
              </div>
              <div className="row" style={{justifyContent : "center"}}>
                <div
                  id="copyright"
                  style={{
                    textAlign : "center"
                  }}
                  className=" fot_copyright"
                >
                  <span className="wsp">
                    © Bản quyền thuộc về{" "}
                    <a  href="#" className>
                        BECARE.VN
                    </a>{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="mobile footer-mobile6">
        <div className="footer-icon">
          <a href="/gio-hang" style={{ color: appTheme.color_main_1 }}>
            <div className="cart-number">{cartNumber}</div>
            <i className="fas fa-shopping-cart"></i>
            Giỏ hàng
          </a>
        </div>
        <div className="footer-icon">
          <a href="/tin-tuc" style={{ color: appTheme.color_main_1 }}>
            <i className="fas fa-scroll"></i>
            Tin tức
          </a>
        </div>
        <div className="footer-icon">
          <a href="/" style={{ color: appTheme.color_main_1 }}>
            <i className="fas fa-home"></i>
            Trang chủ
          </a>
        </div>
        <div className="footer-icon">
          <a href="/danh-muc" style={{ color: appTheme.color_main_1 }}>
            <i className="fas fa-th-list"></i>
            Danh mục
          </a>
        </div>
        <div className="footer-icon">
          <a
            href="/tai-khoan"
            onClick={handleAccountClick}
            style={{ color: appTheme.color_main_1 }}
          >
            <i className="fas fa-user"></i>
            Cá nhân
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
