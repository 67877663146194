import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer1 from "./Footer/Footer1/index";

import Footer2 from "./Footer/Footer2/index";
import TopFooter from "./Footer/TopFooter";

import Footer3 from "./Footer/Footer3/index";
import Footer4 from "./Footer/Footer4/index";
import Footer5 from "./Footer/Footer5/index";

import CustomFooter from "./Footer/CustomFooter/index";
import BannerAdsSection from "./Blog/Blog7/BannerAdsSection";
import BannerAds3 from "./Blog/Blog3/BannerAds";

import { ToastContainer } from "react-toastify";
export default function Footer() {
  const footerType = useSelector((state) => {
    console.log(state.app.appTheme);
    return state.app.appTheme.footer_type;
  });
  const appTheme = useSelector((state) => state.app.appTheme);

  const is_use_footer_html = useSelector(
    (state) => state.app.appTheme.is_use_footer_html
  );
  const tokenInfo = useSelector((state) => state.user.tokenInfo);
  const bannerAds = useSelector((state) => state.app.bannerAds);

  function renderBanners() {
    if (bannerAds?.type_5.length > 0) {
      if (appTheme.home_page_type === 9) {
        return bannerAds.type_5?.length > 0 && <BannerAdsSection banners_ads={bannerAds.type_5} />;
      } else {
        return <BannerAds3 banners={bannerAds.type_5}></BannerAds3>;
      }
    }
  }

  function renderTopFooter() {
    if (appTheme.is_show_list_post_contact === true) {
      return <TopFooter></TopFooter>
    }
  }


  function renderFooter(footerType) {
    return (
      <>
        {" "}
        {renderBanners()}
        {renderTopFooter()}

        <Footer5 />
      </>
    );
  }
  return (
    <React.Fragment>
      {/* <>
      {appTheme.home_page_type === 9 && (
            <BannerAdsSection7 banners_ads={bannerAds.type_5} />
          )}
      </> */}
      <ToastContainer />
      {renderFooter(footerType)}
    </React.Fragment>
  );
}
