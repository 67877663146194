import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import Select from "../../../components/Select";
import Paginate from "../../../components/Paginate";
import { collaboratorActions as a } from "../../../actions/collaboratorActions";
import { formatHmDmy, formatPrice, hideParentElement, showNextElement } from "../../../helper";
export default function ReceiptTab(props) {
  const dispatch = useDispatch();
  const appTheme = useSelector(state => state.app.appTheme);
  const [currentStatus, setCurrentStatus] = useState("Trạng thái");
  const [query, setQuery] = useState({});
  function handleChangeQueryString(q) {
    let queryKeys = [...Object.keys(q)];
    let queryStr = queryKeys.reduce((rs, v) => `${rs}${v}=${q[v]}&`, "?");
    console.log(queryStr);
    dispatch(a.getSharedOrder(queryStr));
  }
  function handleSort(option, e) {
    let newQuery = { ...query };
    let keys = [...Object.keys(option)];
    hideParentElement(e);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] !== "title")
        newQuery[keys[i]] = option[keys[i]];
      else {
        if (currentStatus === option.title)
          return;
        if (option.title === "Tất cả")
          newQuery = {};
        setCurrentStatus(option.title);
        newQuery.page = 1;
      }
    }
    setQuery(newQuery);
    handleChangeQueryString(newQuery);
  }
  function handlePageSelect(page) {
    let cloneQuery = { ...query, ...page };
    handleChangeQueryString(cloneQuery);
  }
  function handleShowInfo(id) {
    window.location.href = `/don-hang/${id}`;
  }
  console.log(props.account)
  return (
    <div className="receipt-tab" style={{
      width:"100%",
      marginLeft : "15px"
    }}>
         <h4>Cấp đại lý: <span style={{ color: "DodgerBlue " }}>{props.account?.agency_type?.name == null ?
            "Chưa có cấp" : props.account?.agency_type?.name
          }</span></h4>
      <table>
      <thead>
              <tr>
                <th className="order-id">Mã đơn hàng</th>
                <th className="date">Thời gian</th>
                <th className="total">Tổng tiền</th>
                <th className="status">T.t đơn hàng</th>
                <th className="status">T.t thanh toán</th>
              </tr>
            </thead>
            <tbody>
              {(props.orders?.data??[]).map(
                (v, i) =>
                  v.line_items_at_time.length > 0 && (
                    <tr key={i} onClick={() => handleShowInfo(v.order_code)}>
                      <td className="order-id" style={{ minWidth: "160px" }}>
                        {v.order_code}
                      </td>
                      <td className="date">{formatHmDmy(v.created_at)}</td>
                
                      <td className="total"> {formatPrice(v.total_final)}</td>
                      <td className="status">{v.order_status_name}</td>
                      <td className="status">
                        {v.payment_status_name}
                        <br />
                       
                    
                      </td>
                    </tr>
                  )
              )}
            </tbody>
      </table>
      <Paginate
        handlePageSelect={handlePageSelect}
        totalPage={props.orders.last_page}
        currentPage={props.orders.current_page} />
    </div >
  )
}