import React, { useEffect, useState } from "react";
import ReceiptTab from "./ReceiptTab";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import {
  formatPrice,
  formatPriceOrContact,
  handleImgErr,
} from "../../../helper";
import { agencyActions as a } from "../../../actions/agencyAction";
import { constants as c } from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import PickerDate from "../../../components/PickerDate/PickerDate";
import RechargeTab from "./ChildPaidTab/RechargeTab";
import WithDrawTab from "./ChildPaidTab/WithDrawTab";

import { appActions } from "../../../actions/appActions";
import RechargeForm from "./ChildPaidTab/RechargeForm";
import WithDrawForm from "./ChildPaidTab/WithDrawForm";

export default function StatisticTab(props) {
  const dispatch = useDispatch();

  const [formClass, setFormClass] = useState("hidden");

  const [currentTab, setCurrentTab] = useState("recharge");
  const [currentForm, setCurrentForm] = useState("CREATE");

  function handleCloseForm() {
    setFormClass("");
  }
  const tabs = {
    recharge: (
      <RechargeTab
      // info={info}
      // orders={orders}
      // account={account}
      // setCurrentTab={setCurrentTab}
      // setCurrentStatisticTab={setCurrentStatisticTab}
      />
    ),

    with_draw: (
      <WithDrawTab
      // orders={orders}

      // report={report}
      // currentTab={currentStatisticTab}
      // setCurrentStatisticTab={setCurrentStatisticTab2}
      // getReport={getReport}
      />
    ),
  };
  function handleShowForm(type) {
    setCurrentForm(type);
    setFormClass("active-modal");
  }
  function handleClose() {
    setFormClass("hidden");
  }
  return (
    <React.Fragment>
      {/* <Header /> */}

      <div className="" style={{
            width: "100%",
            "margin-left": "15px"
      }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="row tabs">
                <div
                  onClick={() => {
                    setCurrentForm("CREATE");

                    setCurrentTab("recharge");

                  }}
                  className={currentTab === "recharge" ? "active" : ""}
                >
                  Nạp tiền
                </div>
                <div
                  onClick={() => {
                    setCurrentForm("CREATE_WITH_DRAW");

                    setCurrentTab("with_draw");
                  }}
                  className={currentTab === "with_draw" ? "active" : ""}
                  style={{ marginLeft: "1.5em", marginRight: "1.5em" }}
                >
                  Rút tiền
                </div>
              </div>
              {/* <div>
              {currentForm == "CREATE" && (

                <button
                  onClick={() => handleShowForm("CREATE")}
                  class="button-submit"
                >
                  <i
                    style={{ verticalAlign: "middle" }}
                    className="fa fa-money"
                  ></i>
                  {"  "}
                  Nạp tiền
                </button>
              )}
                {currentForm == "CREATE_WITH_DRAW" && (

                <button
                style={{background : "red"}}
                  onClick={() => handleShowForm("CREATE_WITH_DRAW")}
                  class="button-submit"
                >
                  <i
                    style={{ verticalAlign: "middle" }}
                    className="fa fa-money"
                  ></i>
                  {"  "}
                  Rút tiền
                </button>
                )}
              </div> */}
            </div>
            {tabs[currentTab]}
          </div>

        {currentForm == "CREATE" && (
          <RechargeForm
            handleClose={handleClose}
            formClass={formClass}
          ></RechargeForm>
        )}
        {/* {currentForm == "CREATE_WITH_DRAW" && (
          <WithDrawForm
            handleClose={handleClose}
            formClass={formClass}
          ></WithDrawForm>
        )} */}
    </React.Fragment>
  );
}
