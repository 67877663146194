import { formatNoD, formatPrice } from "../../../helper";
import { useSelector, useDispatch } from "react-redux";
import InfoPopup from "./InfoPopup";

import { collaboratorActions as a } from "../../../actions/collaboratorActions";
import React, { useEffect, useState } from "react";
import RechargeForm from "./ChildPaidTab/RechargeForm";
import WithDrawForm from "./ChildPaidTab/WithDrawForm";
import InfoPaymentTab from "./InfoPaymentTab";
export default function InfoTab(props) {
  const { balance, deposit } = props.account;
  const { setCurrentTab, setCurrentStatisticTab, profile, agency_type } = props;

  const {
    first_and_last_name,
    bank,
    branch,
    account_name,
    account_number,
    back_card,
    front_card,
    cmnd,
    issued_by,
  } = props.account;

  const {
    payment_limit,
    has_payment_request,
    payment_1_of_month,
    payment_16_of_month,
  } = props.info;
  const appTheme = useSelector((state) => state.app.appTheme);
  const [customClass, setcustomClass] = useState("");
  const [payment_auto, setPaymentAuto] = useState(false);
  const account = useSelector((state) => state.collaborator.account);
  const [formClass, setFormClass] = useState("hidden");
  const [currentForm, setCurrentForm] = useState("CREATE");
  const [showPaid, setShowPaid] = useState(false);
  const [data, setData] = useState({ name: "", price: "" });

  function handleCloseForm() {
    setFormClass("");
  }
  const dispatch = useDispatch();

  function handleShowPopup() {
    setcustomClass("center");
  }
  function handleClosePopup() {
    setcustomClass("");
  }

  function getContentPayment() {
    if (payment_1_of_month == true && payment_16_of_month == true) {
      return "ngày 1 và ngày 16";
    }
    if (payment_1_of_month == true) {
      return "ngày 1";
    }
    if (payment_16_of_month == true) {
      return "ngày 16";
    }
    return "";
  }

  function updatePaymentAuto(v) {
    setPaymentAuto(v);
    var data = account;
    data.payment_auto = v;
    dispatch(a.updateInfo(data));
  }

  function handleClose() {
    setFormClass("hidden");
  }

  function handleShowForm(type) {
    setCurrentForm(type);
    setFormClass("active-modal");
  }

  function handlePaid(item) {
    setShowPaid(true);
    setData(item);
  }
  return (
    <React.Fragment>
      <div
        style={{
          width: "100%",
          "margin-left": "18px",
        }}
      >
        <div className="info-tab">
          <div className="column" style={{ display: "flex" }}>
            <div
              className="income-card"
              // onClick={() => {
              //   setCurrentTab("statistic");
              //   setCurrentStatisticTab("receipt");
              // }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src="/img/commission.png" alt="" />
                <span>Số dư</span>
              </div>
              <div className="row">
                {/* <div>{number_order} Đơn hàng</div> */}
                <div>
                  {formatPrice(balance)} {"  "}
                  {/* {parseInt(balance) > 0 &&
                             <span
                             onClick={() => handleShowForm("CREATE_WITH_DRAW")}
                             style={{
                               color: "blue",
                               fontSize: "14px",
                               "margin-left": "15px",
                               "text-decoration": "underline",
                               cursor : "pointer"
                             }}
                           >
                             Rút tiền
                           </span>} */}
                </div>
              </div>
            </div>

            <div
              className="income-card"
              style={{
                marginTop: "0px",
                marginLeft: "20px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src="/img/receipt.png" alt="" />
                <span>Quỹ đại lý</span>
              </div>
              <div className="row">
                {/* <div>{number_order} Đơn hàng</div> */}
                <div>
                  {formatPrice(deposit)}
                  <span
                    onClick={() =>     setFormClass("active-modal")
                  }
                    style={{
                      color: "blue",
                      fontSize: "14px",
                      "margin-left": "15px",
                      "text-decoration": "underline",
                      cursor: "pointer",
                    }}
                  >
                    Nạp tiền
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <InfoPaymentTab
              setCurrentTab={setCurrentTab}
              setCurrentStatisticTab={setCurrentStatisticTab}
              profile={profile}
              agency_type={agency_type}
              account={account}
              info={props.account}
              onClose={handleClosePopup}
              customClass={customClass}
              handleClose={handleClose}
              formClass={formClass}


            />
        {/* <div class="container">
          <ul
            class="progressbar"
            style={{
              display: "flex",
              "justify-content": "center",
            }}
          >
            <li class="active">Chọn gói</li>
            <li>Thanh toán</li>
          </ul>
        </div> */}
        {/* {showPaid === true && (
          <div className="payment-tab" style={{ marginTop: "15px" }}>
            <div className="payment-info" style={{ width: "100%" }}>
              <div className="top">
                <img src="/img/wallet.png" alt="" />
                <span>Nạp tiền đại lý</span>
              </div>

              <div className="content content-agency-deduct">
                <div
                  className="row"
                  style={{
                    marginBottom: "5px",
                    fontStyle: "italic",
                    color: appTheme.color_main_1,
                  }}
                >
                  ***Vui lòng chuyển khoản đến tài khoản ngân hàng sau và nhấn
                  vào nút đã chuyển tiền để được nạp tiền vào tài khoản
                </div>

                <div className="row">
                  <label>Ngân hàng: {"  "}</label>
                  <span>&nbsp;Ngân hàng Việt Nam Thịnh Vượng (VP BANK)</span>
                </div>
                <div className="row">
                  <label>Số tài khoản: {"  "}</label>
                  <span>&nbsp;818187777</span>
                </div>
                <div className="row">
                  <label>Tên thụ hưởng: {"  "}</label>
                  <span>&nbsp;CÔNG TY CỔ PHẦN IKITECH VIỆT NAM</span>
                </div>
                <div className="row">
                  <label>** Nội dung chuyển khoản: {"  "}</label>
                  <span style={{ fontWeight: "500" }}>
                    &nbsp;BECARE{profile.phone_number}
                  </span>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ display: "flex" }}>
                      <button
                        style={{
                          color: "gray",
                          margin: "5px 0px 0px",
                          marginRight: "10px",
                        }}
                        className="button-cancel"
                        onClick={() => {
                          setShowPaid(false);
                          setData({ name: "", price: "" });
                        }}
                      >
                        Hủy bỏ
                      </button>
                      <button
                        onClick={() =>setFormClass("active-modal")
                      }
                        style={{
                          background: appTheme.color_main_1,
                          margin: "5px 0px 0px 0px",
                          cursor: "pointer",
                        }}
                      >
                        Nạp tiền
                      </button>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>

            <InfoPopup
              setCurrentTab={setCurrentTab}
              setCurrentStatisticTab={setCurrentStatisticTab}
              profile={profile}
              agency_type={agency_type}
              account={account}
              info={props.account}
              onClose={handleClosePopup}
              customClass={customClass}

            />
            <InfoPopup
              customClass={customClass}
              info={props.account}
              onClose={handleClosePopup}
            />
            {currentForm == "CREATE" && (
              <RechargeForm
                data={data}
                setCurrentTab={props.setCurrentTab}
                handleClose={handleClose}
                formClass={formClass}
              ></RechargeForm>
            )}
          </div>
        )} */}
        {/* {showPaid === false && (
          <div className="payment-tab" style={{ marginTop: "15px" }}>
            <div className="payment-info" style={{ width: "100%" }}>
              <div className="top">
                <img src="/img/wallet.png" alt="" />
                <span>Chọn gói</span>
              </div>

              <div className="content content-agency-deduct">
                <div className="list-item">
                  {agency_type.data?.map((v) => {
                    return (
                      <div className="item">
                        <p
                          style={{
                            "font-size": "18px",
                            "font-weight": "600",
                            "padding-bottom": "10px",
                            color: "#888585",
                          }}
                        >
                          {" "}
                          {v.name}
                        </p>
                        <p style={{ fontSize: "15px" }}>
                          {formatPrice(v.plan)}
                        </p>

                        <p>Chiết khấu {v.commission_percent}%</p>
                        <div
                          style={{
                            position: "absolute",
                            bottom: "0px",
                            width: "100%",
                            margin: "auto",
                          }}
                        >
                          <button
                            onClick={() =>
                              handlePaid({ name: v.name, price: v.plan })
                            }
                            style={{ cursor: "pointer" }}
                            className="button-submit"
                          >
                            Chọn gói
                          </button>{" "}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </React.Fragment>
  );
}
