function getMeta(metaName) {
  const metas = document.getElementsByTagName("meta");
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute("name") === metaName) {
      return metas[i].getAttribute("content");
    }
  }
  return "";
}
exports.constants = {
  DEFAULT_TITLE:
    "Xây dựng hệ thống bán hàng mạnh mẽ, mang thương hiệu của riêng bạn.",
  DEFAULT_HOME_TITLE: "Trang chủ",
  DEFAULT_ADDRESS: "Số 41, Ngõ 76, Phố Mai Dịch, Cầu Giấy, Hà Nội.",
  DEFAULT_PRODUCT_IMG: "/img/default_product.jpg",
  DEFAULT_SHORTCUT: "/img/default_shortcut.png",
  DEFAULT_HEADER_BACKGROUND_COLOR: "white",
  DEFAULT_HEADER_TEXT_COLOR: "#757575",
  DEFAULT_LOGO: "",
  DEFAULT_MAIL: "ikitech.vn@gmail.com",
  MAIN_PAGE_URL: "https://ikitech.vn/",
  DEFAULT_PHONE: "0246.0278.753",
  DEFAULT_COLOR: "#e62e04",
  LOADING_WHEN_SUBMIT_REGISTER: "LOADING_WHEN_SUBMIT_REGISTER",
  //STATUS
  API_URL: getMeta("domain_api") + "/api",
  CLEAR_MESSAGE: "CLEAR_MESSAGE",
  LOADING: "LOADING",
  NONE: "NONE",
  FAILURE: "FAILURE",
  SUCCESS: "SUCCESS",
  //APP
  SHOW_POPUP_MAIN: "SHOW_POPUP_MAIN",
  GET_WEB_THEME_SUCCESS: "GET_WEB_THEME_SUCCESS",
  GET_WEB_THEME_FAILURE: "GET_WEB_THEME_FAILURE",
  //
  GET_INFO_STORE_SUCCESS: "GET_INFO_STORE_SUCCESS",
  GET_INFO_STORE_FAILURE: "GET_INFO_STORE_FAILURE",
  //
  POPUP_MAIN: "POPUP_MAIN",
  COLLABORATOR_REGIS_POPUP: "COLLABORATOR_REGIS_POPUP",
  AGENCY_REGIS_POPUP: "AGENCY_REGIS_POPUP",
  TOGGLE_CHAT_STATUS: "TOGGLE_CHAT_STATUS",
  GETTING_WEB_THEME: "GETTING_WEB_THEME",
  FORGOT_PASS_POPUP: "FORGOT_PASS_POPUP",
  EMAIL_LOGIN_POPUP: "EMAIL_LOGIN_POPUP",
  AUTOHIDE_POPUP: "AUTOHIDE_POPUP",
  PROFILE_POPUP: "PROFILE_POPUP",
  RATTING_POPUP: "RATTING_POPUP",
  LOADING_POPUP: "LOADING_POPUP",
  MESSAGE_POPUP: "MESSAGE_POPUP",
  CHANGE_POPUP: "CHANGE_POPUP",
  PHONE_POPUP: "PHONE_POPUP",
  VOUCHER_DETAIL_POPUP: "VOUCHER_DETAIL_POPUP",
  COMBO_DETAIL_POPUP: "COMBO_DETAIL_POPUP",
  GET_BONUS_PRODUCT_POPUP: "GET_BONUS_PRODUCT_POPUP",
  GET_BONUS_LADDER_PRODUCT_POPUP: "GET_BONUS_LADDER_PRODUCT_POPUP",

  BONUS_PRODUCT_DETAIL_POPUP: "BONUS_PRODUCT_DETAIL_POPUP",
  GET_BONUS_PRODUCT_LADDER_POPUP: "GET_BONUS_PRODUCT_LADDER_POPUP",
  BONUS_PRODUCT_LADDER_DETAIL_POPUP: "BONUS_PRODUCT_LADDER_DETAIL_POPUP",
  CALCULATE_PRICE: "CALCULATE_PRICE",
  GET_COMBOS_POPUP: "GET_COMBOS_POPUP",
  RESET_BONUS_PRODUCT_POPUP: "RESET_BONUS_PRODUCT_POPUP",
  RESET_COMBOS_POPUP: "RESET_COMBOS_POPUP",
  LOGIN_POPUP: "LOGIN_POPUP",
  REGIS_POPUP: "REGIS_POPOP",
  ORDER_POPUP: "ORDER_POPUP",
  OTP_POPUP: "OTP_POPUP",
  NO_POPUP: "NO_POPUP",
  LOGOUT: "LOGOUT",
  AGENCY_CHARGE_POPUP: "AGENCY_CHARGE_POPUP",
  //PAGE
  GET_HOME_SUCCESS: "GET_HOME_SUCCESS",
  GET_HOME_FAILURE: "GET_HOME_FAILRE",
  SET_IS_LOADED: "SET_IS_LOADED",
  GET_HOME_LOADING: "GET_HOME_LOADING",
  GET_HOME_DONE: "GET_HOME_DONE",
  //PRODUCT
  GET_PURCHASED_PRODUCTS_SUCCESS: "GET_PURCHASED_PRODUCTS_SUCCESS",
  GET_PURCHASED_PRODUCTS_FAILURE: "GET_PURCHASED_PRODUCTS_FAILURE",
  GET_FAVORITE_PRODUCT_SUCCESS: "GET_FAVORITE_PRODUCT_SUCCESS",
  GET_FAVORITE_PRODUCT_FAILURE: "GET_FAVORITE_PRODUCT_FAILURE",
  GET_SIMILAR_PRODUCTS_SUCCESS: "GET_SIMILAR_PRODUCTS_SUCCESS",
  GET_SIMILAR_PRODUCTS_FAILURE: "GET_SIMILAR_PRODUCTS_FAILURE",
  RESET_PRODUCTS_LIST_STATUS: "RESET_PRODUCTS_LIST_STATUS",
  GET_PRODUCT_REVIEW_SUCCESS: "GET_PRODUCT_REVIEW_SUCCESS",
  GET_PRODUCT_REVIEW_FAILURE: "GET_PRODUCT_REVIEW_FAILURE",
  ADD_TO_WISHLIST_SUCCESS: "ADD_TO_WISHLIST_SUUCESS",
  ADD_TO_WISHLIST_FAILURE: "ADD_TO_WISHLIST_FAILURE",
  REVIEW_PRODUCT_SUCCESS: "REVIEW_PRODUCT_SUCCESS",
  REVIEW_PRODUCT_FAILURE: "REVIEW_PRODUCT_FAILURE",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",
  RESET_PRODUCT_STATUS: "RESET_PRODUCT_STATUS",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAILURE: "GET_PRODUCT_FAILURE",
  SET_ERROR_SELECT_DISTRIBUTE: "SET_ERROR_SELECT_DISTRIBUTE",
  RESET_ORDERS_LIST_DEDUCT_STATUS: "RESET_ORDERS_LIST_DEDUCT_STATUS",
  //CATEGORY
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_FAILURE: "GET_CATEGORY_FAILURE",
  //NEWS
  GET_NEWS_CATEGORY_SUCCESS: "GET_NEWS_CATEGORY_SUCCESS",
  GET_NEWS_CATEGORY_FAILURE: "GET_NEWS_CATEGORY_FAILURE",
  RESET_NEWS_LIST_STATUS: "RESET_NEWS_LIST_STATUS",
  GET_ALL_NEWS_SUCCESS: "GET_ALL_NEWS_SUCCESS",
  GET_ALL_NEWS_FAILURE: "GET_ALL_NEWS_FAILURE",
  RESET_NEWS_STATUS: "RESET_NEWS_STATUS",
  GET_NEWS_SUCCESS: "GET_NEWS_SUCCESS",
  GET_NEWS_FAILURE: "GET_NEWS_FAILURE",
  //USER
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  REGIS_SUCCESS: "REGIS_SUCCESS",
  REGIS_FAILURE: "REGIS_FAILURE",
  PHONE_REGISTERED: "PHONE_REGISTERED",
  EMAIL_REGISTERED: "EMAIL_REGISTERED",
  // POINTS_HISTORY
  GET_POINTS_HISTORY_SUCCESS: "GET_POINTS_HISTORY_SUCCESS",
  GET_POINTS_HISTORY_FAILURE: "GET_POINTS_HISTORY_FAILURE",
  //POINTS
  GET_REWARD_POINTS_FAILURE: "GET_REWARD_POINTS_FAILURE",
  GET_REWARD_POINTS_SUCCESS: "GET_REWARD_POINTS_SUCCESS",
  //
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAILURE: "GET_PROFILE_FAILURE",
  //
  PHONE_NOT_REGISTERED: "PHONE_NOT_REGISTERED",
  EMAIL_NOT_REGISTERED: "EMAIL_NOT_REGISTERED",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  GET_USER_ADDRESS_SUCCESS: "GET_USER_ADDRESS_SUCCESS",
  GET_USER_ADDRESS_FAILURE: "GET_USER_ADDRESS_FAILURE",
  ADD_USER_ADDRESS_SUCCESS: "ADD_USER_ADDRESS_SUCCESS",
  ADD_USER_ADDRESS_FAILURE: "ADD_USER_ADDRESS_FAILURE",
  UPDATE_USER_ADDRESS_SUCCESS: "UPDATE_USER_ADDRESS_SUCCESS",
  UPDATE_USER_ADDRESS_FAILURE: "UPDATE_USER_ADDRESS_FAILURE",
  DELETE_USER_ADDRESS_SUCCESS: "DELETE_USER_ADDRESS_SUCCESS",
  DELETE_USER_ADDRESS_FAILURE: "DELETE_USER_ADDRESS_FAILURE",
  GET_USER_REVIEW_SUCCESS: "GET_USER_REVIEW_SUCCESS",
  RESET_GET_USER_REVIEW: "RESET_GET_USER_REVIEW",
  GET_USER_REVIEW_FAILURE: "GET_USER_REVIEW_FAILURE",
  GET_USER_AWAIT_REVIEW_SUCCESS: "GET_USER_AWAIT_REVIEW_SUCCESS",
  GET_USER_AWAIT_REVIEW_FAILURE: "GET_USER_AWAIT_REVIEW_FAILURE",
  RESET_GET_USER_AWAIT_REVIEW: "RESET_GET_USER_AWAIT_REVIEW",
  GET_USER_BADGES_SUCCESS: "GET_USER_BADGES_SUCCESS",
  GET_USER_BADGES_FAILURE: "GET_USER_BADGES_FAILURE",
  GET_USER_NOTIFY_SUCCESS: "GET_USER_NOTIFY_SUCCESS",
  GET_USER_NOTIFY_FAILURE: "GET_USER_NOTIFY_FAILURE",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
  TOGGLE_BOX_CHAT: "TOGGLE_BOX_CHAT",
  //AGENCY
  GET_AGENCY_ACCOUNT_SUCCESS: "GET_AGENCY_ACCOUNT_SUCCESS",
  GET_AGENCY_ACCOUNT_FAILURE: "GET_AGENCY_ACCOUNT_FAILURE",
  UPDATE_AGENCY_INFO_SUCCESS: "UPDATE_AGENCY_INFO_SUCCESS",
  UPDATE_INFO_AGENCY_FAILURE: "UPDATE_INFO_AGENCY_FAILURE",
  GET_AGENCY_INFO_SUCCESS: "GET_AGENCY_INFO_SUCCESS",
  GET_AGENCY_INFO_FAILURE: "GET_AGENCY_INFO_FAILURE",
  GET_AGENCY_TYPE_SUCCESS: "GET_AGENCY_TYPE_SUCCESS",
  GET_AGENCY_TYPE_FAILURE: "GET_AGENCY_TYPE_FAILURE",

  //COLLABORATOR
  GET_COLLABORATOR_ACCOUNT_SUCCESS: "GET_COLLABORATOR_ACCOUNT_SUCCESS",
  GET_COLLABORATOR_ACCOUNT_FAILURE: "GET_COLLABORATOR_ACCOUNT_FAILURE",
  UPDATE_COLLABORATOR_INFO_SUCCESS: "UPDATE_COLLABORATOR_INFO_SUCCESS",
  UPDATE_INFO_COLLABORATOR_FAILURE: "UPDATE_INFO_COLLABORATOR_FAILURE",
  GET_COLLABORATOR_INFO_SUCCESS: "GET_COLLABORATOR_INFO_SUCCESS",
  GET_COLLABORATOR_INFO_FAILURE: "GET_COLLABORATOR_INFO_FAILURE",
  GET_SHARED_ORDERS_SUCCESS: "GET_SHARED_ORDERS_SUCCESS",
  GET_SHARED_ORDERS_FAILURE: "GET_SHARED_ORDERS_FAILURE",
  GET_BALANCE_HISTORY_SUCCESS: "GET_BALANCE_HISTORY_SUCCESS",
  GET_BALANCE_HISTORY_FAILURE: "GET_BALANCE_HISTORY_FAILURE",
  GET_BALANCE_HISTORY_DISPOSIT_SUCCESS: "GET_BALANCE_HISTORY_DISPOSIT_SUCCESS",
  GET_BALANCE_HISTORY_DISPOSIT_FAILURE: "GET_BALANCE_HISTORY_DISPOSIT_FAILURE",
  GET_BONUS_HISTORY_SUCCESS: "GET_BONUS_HISTORY_SUCCESS",
  GET_BONUS_HISTORY_FAILURE: "GET_BONUS_HISTORY_FAILURE",
  REQUEST_PAYMENT_SUCCESS: "REQUEST_PAYMENT_SUCCESS",
  REQUEST_PAYMENT_FAILURE: "REQUEST_PAYMENT_FAILURE",
  GET_COLLABORATOR_REPORT_SUCCESS: "GET_COLLABORATOR_REPORT_SUCCESS",
  GET_COLLABORATOR_REPORT_FAILURE: "GET_COLLABORATOR_REPORT_FAILURE",
  GET_AGENCY_REPORT_SUCCESS: "GET_AGENCY_REPORT_SUCCESS",
  GET_AGENCY_REPORT_FAILURE: "GET_AGENCY_REPORT_FAILURE",
  //CART
  ORDER_SUCCESS: "ORDER_SUCCESS",
  ORDER_FAILURE: "ORDER_FAILURE",
  ADD_CART_SUCCESS: "ADD_CART_SUCCESS",
  ADD_CART_FAILURE: "ADD_CART_FAILURE",
  GET_CART_SUCCESS: "GET_CART_SUCCESS",
  GET_CART_FAILURE: "GET_CART_FAILURE",
  CHANGE_NUMBER_SUCCESS: "CHANGE_NUMBER_SUCCESS",
  CHANGE_NUMBER_FAILURE: "CHANGE_NUMBER_FAILURE",
  GET_SHIPMENT_FEE_SUCCESS: "GET_SHIPMENT_FEE_SUCCESS",
  GET_SHIPMENT_FEE_FAILURE: "GET_SHIPMENT_FEE_FAILURE",
  CHANGE_PAYMENT_METHOD_SUCCESS: "CHANGE_PAYMENT_METHOD_SUCCESS",
  CHANGE_PAYMENT_METHOD_FAILURE: "CHANGE_PAYMENT_METHOD_FAILURE",
  GET_PAYMENT_METHODS_SUCCESS: "GET_PAYMENT_METHODS_SUCCESS",
  GET_PAYMENT_METHODS_FAILURE: "GET_PAYMENT_METHODS_FAILURE",
  RESET_ORDERS_LIST_STATUS: "RESET_ORDERS_LIST_STATUS",
  GET_ORDERS_LIST_SUCCESS: "GET_ORDERS_LIST_SUCCESS",
  GET_ORDERS_LIST_FAILURE: "GET_ORDERS_LIST_FAILURE",
  GET_ORDER_INFO_SUCCESS: "GET_ORDER_INFO_SUCCESS",
  GET_ORDER_INFO_FAILURE: "GET_ORDER_INFO_FAILURE",
  CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
  CANCEL_ORDER_FAILURE: "CANCEL_ORDER_FAILURE",
  RESET_ORDER_INFO_STATUS: "RESET_ORDER_INFO_STATUS",
  GET_ORDERS_LIST_DEDUCT_SUCCESS: "GET_ORDERS_LIST_DEDUCT_SUCCESS",
  GET_ORDERS_LIST_DEDUCT_FAILURE: "GET_ORDERS_LIST_DEDUCT_FAILURE",
  //ADDRESS
  SET_ADDRESS_DEFAULT_SUCCESS: "SET_ADDRESS_DEFAULT_SUCCESS",
  SET_ADDRESS_DEFAULT_FAILURE: "SET_ADDRESS_DEFAULT_FAILURE",
  GET_PROVINCES_LIST_SUCCESS: "GET_PROVINCES_LIST_SUCCESS",
  GET_PROVINCES_LIST_FAILURE: "GET_PROVINCES_LIST_FAILURE",
  GET_DISTRICTS_LIST_SUCCESS: "GET_DISTRICTS_LIST_SUCCESS",
  GET_DISTRICTS_LIST_FAILURE: "GET_DISTRICTS_LIST_FAILURE",
  GET_WARDS_LIST_SUCCESS: "GET_WARDS_LIST_SUCCESS",
  GET_WARDS_LIST_FAILURE: "GET_WARDS_LIST_FAILURE",
  //VOUCHER
  GET_ALL_VOUCHERS_SUCCESS: "GET_ALL_VOUCHERS_SUCCESS",
  GET_ALL_VOUCHERS_FAILURE: "GET_ALL_VOUCHERS_FAILURE",
  GET_ALL_BONUS_PRODUCT_SUCCESS: "GET_ALL_BONUS_PRODUCT_SUCCESS",
  GET_ALL_BONUS_PRODUCT_FAILURE: "GET_ALL_BONUS_PRODUCT_FAILURE",
  RESET_VOUCHERS_POPUP: "RESET_VOUCHERS_POPUP",
  APPLY_VOUCHER_SUCCESS: "APPLY_VOUCHER_SUCCESS",
  APPLY_VOUCHER_FAILURE: "APPLY_VOUCHER_FAILURE",
  GET_VOUCHERS_POPUP: "GET_VOUCHERS_POPUP",
  //COMBO
  GET_ALL_COMBO_SUCCESS: "GET_ALL_COMBO_SUCCESS",
  GET_ALL_COMBO_FAILURE: "GET_ALL_COMBO_FAILURE",
  //SEARCH
  UPDATE_SEARCH_TEXT: "UPDATE_SEARCH_TEXT",
  GET_LIST_BANNERS_HOME: "GET_LIST_BANNERS_HOME",
  GET_LIST_BANNERS_ADS_HOME: "GET_LIST_BANNERS_ADS_HOME",
  GET_LIST_SALE_PRODUCT_HOME: "GET_LIST_SALE_PRODUCT_HOME",
  GET_LIST_NEW_PRODUCT_HOME: "GET_LIST_NEW_PRODUCT_HOME",
  GET_LIST_HOT_PRODUCT_HOME: "GET_LIST_HOT_PRODUCT_HOME",
  GET_LIST_PRODUCT_BY_CATEGORY_HOME: "GET_LIST_PRODUCT_BY_CATEGORY_HOME",
  GET_LIST_NEW_POST_HOME: "GET_LIST_NEW_POST_HOME",
  GET_LIST_POST_WITH_CATEGORY_HOME: "GET_LIST_POST_WITH_CATEGORY_HOME",
  //VOUCHER_SUCESS
  VOUCHER_SUCCESS: "VOUCHER_SUCCESS",
  VOUCHER_FAILURE: "VOUCHER_FAILURE",
  ADD_RECHARGE_SUCCESS : "ADD_RECHARGE_SUCCESS",
  GET_AGENCY_RECHARGE_SUCCESS  : "GET_AGENCY_RECHARGE_SUCCESS",
  GET_AGENCY_RECHARGE_FAILURE : "GET_AGENCY_RECHARGE_FAILURE",
  RESET_AGENCY_WITH_DRAW_STATUS : "RESET_AGENCY_WITH_DRAW_STATUS",
  ADD_RECHARGE_FAILURE : "ADD_RECHARGE_FAILURE",
  RESET_AGENCY_RECHARGE_STATUS : "RESET_AGENCY_RECHARGE_STATUS"

};
