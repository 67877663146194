import React, { useEffect, useState } from "react";
import ReceiptTab from "./ReceiptTab";
import { Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { formatPrice, formatPriceOrContact, handleImgErr } from "../../../helper";
import { agencyActions as a } from "../../../actions/agencyAction";
import { constants as c } from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import PickerDate from "../../../components/PickerDate/PickerDate";
export default function StatisticTab(props) {
  const [currentTab, setCurrentTab] = useState("receipt");
  const tabs = {
    receipt: <ReceiptTab orders={props.orders} />,
  };
  const [typeChart, setTypeChart] = useState("TOTAL_FINAL");
  const dispatch = useDispatch();
  const account = useSelector((state) => state.collaborator.account);

  useEffect(() => {

    if (account.status === c.LOADING) {
      dispatch(a.getAgencyReport());
    }

    if (props.currentTab == "receipt") {
      setCurrentTab("receipt");
    }
    var reportData2 = props.report;


  }, []);


  function handleSelect(ranges) {
    console.log(ranges);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  };

  function changeSelect(event) {
    setTypeChart(event.target.value)
  }

  var reportData2 = props.report;

  var labels = [];
  var total_final = {
    data: [],
    label: "Tổng tiền hàng",
    backgroundColor: "#3e95cd",
    fill: false
  };

  var total_order_count = {
    data: [],
    label: "Số lượng đơn hàng",
    backgroundColor: "#ff6666",
    fill: false
  };

  var reportData = {
    labels: labels,
    datasets: [
      total_final,
      total_order_count
    ]
  }

  if (reportData2 == null) {
    dispatch(a.getAgencyReport());
  } else {
    labels = reportData2.data_prime_time?.charts.map((dataInTime) => dataInTime.time);
    total_final = {
      data: reportData2.data_prime_time?.charts.map((dataInTime) => dataInTime.total_final),
      label: "Tổng tiền hàng",
      backgroundColor: "#3e95cd",
      fill: false
    }
    total_order_count = {
      data: reportData2.data_prime_time?.charts.map((dataInTime) => dataInTime.total_order_count),
      label: "Số lượng đơn hàng",
      backgroundColor: "#ff6666",
      fill: false
    }

    reportData = {
      labels: labels,
      datasets: [
        total_final,
        total_order_count
      ]
    }
  }


  function onChangeDate(query) {
    props.getReport(query)
  }

  if (typeChart == "TOTAL_FINAL") {
    reportData = {
      labels: labels,
      datasets: [
        total_final,

      ]
    }
  }
  if (typeChart == "ORDER_COUNT") {
    reportData = {
      labels: labels,
      datasets: [

        total_order_count,

      ]
    }
  }


  return (

    reportData2 == null ? <div></div> : <div className="balance-tab">
    <div className="col col-7">
          <PickerDate onChangeDate={onChangeDate} />
          <div style={{ marginTop: '10px' }}>
            <p> Tổng tiền hàng: ₫{formatPrice(reportData2?.data_prime_time?.total_final ?? 0)}</p>
            <p> Số lượng đơn: {reportData2?.data_prime_time?.total_order_count ?? 0}</p>
          </div>
        </div>
        <div className="col-6">
          <div className="row float-end">
            <label style={{ paddingTop: '7px' }}> Biểu đồ chi tiết </label>
            <select onChange={changeSelect} name="" id="input" class="form-control form-select" required="" style={
              { maxWidth: 250 }
            }>
              <option value="TOTAL_FINAL">Theo tổng tiền hàng</option>
              <option value="ORDER_COUNT"> Theo số lượng đơn hàng</option>

            </select>

          </div>
        </div>
        <Bar
          data={
            reportData
          }
          options={{
            title: {
              display: true,
              text: "Báo cáo doanh số "
            },
            legend: {
              display: true,
              position: "bottom"
            }
          }}
        />

        {/* <div className="mini-tabs">
        <div
          onClick={() => {
            setCurrentTab("receipt")
            props.setCurrentStatisticTab("receipt")
          }}
          className={currentTab === "receipt" ? "active" : ""}
        >
          <img src="/img/receipt.png" alt="" />
          <span>Đơn hàng của bạn</span>
        </div>
    
        
      </div> */}
        {/* {tabs[currentTab]} */}
      </div>
  );
}
