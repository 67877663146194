import { useSelector, useDispatch } from "react-redux";
import InfoPopup from "./InfoPopup";
import { handleImgErr, formatPrice } from "../../../helper";
import { collaboratorActions as a } from "../../../actions/collaboratorActions";
import React, { useEffect, useState } from "react";
import WithDrawForm from "./ChildPaidTab/WithDrawForm";

export default function PaymentTab(props) {
  const {
    first_and_last_name,
    bank,
    branch,
    account_name,
    account_number,
    back_card,
    front_card,
    cmnd,
    issued_by,
    balance,
  } = props.account;

  console.log(props.account);

  const {
    payment_limit,
    has_payment_request,
    payment_1_of_month,
    payment_16_of_month,
  } = props.info;
  const [customClass, setcustomClass] = useState("");

  const appTheme = useSelector((state) => state.app.appTheme);
  const [payment_auto, setPaymentAuto] = useState(false);
  const account = useSelector((state) => state.collaborator.account);
  const [formClass, setFormClass] = useState("hidden");
  const [currentForm, setCurrentForm] = useState("CREATE");

  const dispatch = useDispatch();
  function handleShowForm() {
    setFormClass("active-modal");
  }

  function handleShowPopup() {
    setcustomClass("center");
  }
  function handleClosePopup() {
    setcustomClass("");
  }

  function getContentPayment() {
    if (payment_1_of_month == true && payment_16_of_month == true) {
      return "ngày 1 và ngày 16";
    }
    if (payment_1_of_month == true) {
      return "ngày 1";
    }
    if (payment_16_of_month == true) {
      return "ngày 16";
    }
    return "";
  }

  function updatePaymentAuto(v) {
    setPaymentAuto(v);
    var data = account;
    data.payment_auto = v;
    dispatch(a.updateInfo(data));
  }

  function handleClose() {
    setFormClass("hidden");
  }

  return (
    <div className="payment-tab" style={{ paddingLeft: "10px", width: "100%" }}>
      <div className="payment-info" style={{ width: "60%" }}>
        <div className="top">
          <img src="/img/wallet.png" alt="" />
          <span>Thông tin thanh toán</span>
        </div>
        <div className="content">
          <div className="row">
            <label>Họ và tên: </label>
            <span>{first_and_last_name}</span>
          </div>
          <div className="row">
            <label>Tên tài khoản: </label>
            <span>{account_name}</span>
          </div>
          <div className="row">
            <label>Số tài khoản: </label>
            <span>{account_number}</span>
          </div>
          <div className="row">
            <label>Ngân hàng: </label>
            <span>{bank}</span>
          </div>
          <div className="row">
            <label>Chi nhánh: </label>
            <span>{branch}</span>
          </div>

          <div className="row">
            <label>CMND/CCCD: </label>
            <span>{cmnd}</span>
          </div>

          <div className="row">
            <label>Nơi cấp: </label>
            <span>{issued_by ?? ""}</span>
          </div>

          <div className="idcard-img row">
            <div style={{ marginRight: "1em", marginTop: "0.5em" }}>
              <label>Mặt trước: </label>
              <div className="image">
                <div className="img-container">
                  <img src={front_card} alt="" onError={handleImgErr} />
                </div>
              </div>
            </div>
            <div style={{ marginTop: "0.5em" }}>
              <label>Mặt sau: </label>
              <div className="image">
                <div className="img-container">
                  <img src={back_card} alt="" onError={handleImgErr} />
                </div>
              </div>
            </div>
          </div>

          <button
            onClick={handleShowPopup}
            style={{ background: appTheme.color_main_1 }}
          >
            Cập nhật thông tin
          </button>
        </div>
      </div>

      <div className="payment-policy" style={{ width: "37%" }}>
        <div>
          <div className="top">
            <img src="/img/rules.png" alt="" />
            <span>Hình thức thanh toán</span>
          </div>

          {parseInt(balance) > 0 ? (
            <button
              onClick={() => handleShowForm("CREATE_WITH_DRAW")}
              style={{ cursor: "pointer", marginBottom: "10px" }}
              className="button-submit"
            >
              Rút tiền
            </button>
          ) : (
            <button
              style={{
                cursor: "no-drop",
                marginBottom: "10px",
                background: "gray",
              }}
              className="button-submit"
            >
              Rút tiền
            </button>
          )}
          <div className="" style={{margin : "0px 5px 5px 5px" , lineHeight: "1.2" , color : "#676161"}}>
            <span>
              - Lệnh rút tiền tạo trước <b>8h sáng</b> được thực hiện chuyển
              khoản trong cùng ngày làm việc (Không tính t7, chủ nhật và ngày lễ)
              {/* - Bạn cần có số dư tối thiểu là{" "}
              <b>{formatPrice("10000")} VNĐ</b> để được thanh toán định kỳ */}
            </span>
          </div>
          <div className="" style={{margin : "5px" , lineHeight: "1.2" , color : "#676161"}}>
            <span>
              - Lệnh rút tiền tạo sau <b>8h sáng</b> sẽ được thực hiện chuyển
              khoản vào ngày làm việc tiếp theo (Không tính t7, chủ nhật và ngày
              lễ)
              {/* - Bạn cần có số dư tối thiểu là{" "}
              <b>{formatPrice("10000")} VNĐ</b> để được thanh toán định kỳ */}
            </span>
          </div>

          <WithDrawForm
            setCurrentTab={props.setCurrentTab}
            account={props.account}
            handleClose={handleClose}
            formClass={formClass}
          ></WithDrawForm>
        </div>
      </div>
      <InfoPopup
        customClass={customClass}
        info={props.account}
        onClose={handleClosePopup}
      />
    </div>
  );
}
