import { useSelector, useDispatch } from "react-redux";
import Select from "../../../../components/Select";
import { appActions as appActions } from "../../../../actions/appActions";
import { constants as c } from "../../../../constants";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { agencyActions as a } from "../../../../actions/agencyAction";
import { formatPrice } from "../../../../helper";

export default function RechargeForm(props) {
  const dispatch = useDispatch();

  const [banks, setBanks] = useState([]);
  const [errMsg, setMessage] = useState("");
  const [state, setState] = useState({
    bank_name: "",
    money: "",
    bank_account_name: "",
    bank_number: "",
  });

  function onChange(e) {
    var { name, value } = e.target;
    setState({ ...state, [name]: value });
  }

  const { bank_name, money, bank_account_name, bank_number, reason } = state;


  // useEffect(()=>{
  //   setState({ ...state, money: props.data.name });

  // } , [props.data])

  useEffect(async () => {
    var res = await fetch(`https://api.vietqr.io/v2/banks`);
    try {
      res.json().then((res) => {
        setBanks(res.data);
      });
    } catch (error) {}
    setBanks([]);
  }, []);

  function handleSubmit() {
    var {price} = props.data
    if (price == "" || parseInt(price) == 0 || isNaN(parseInt(price))) {
      setMessage("Số tiền không hợp lệ!");
      return;
    } else {
      dispatch(
        a.postAgencyRechage({
          money: props.data.price,
          bank_name: bank_name,
          bank_account_name: bank_account_name,
          bank_number: bank_number,
          reason: reason,
        })
      );
      dispatch(appActions.changePopup(c.MESSAGE_POPUP));
      props.setCurrentTab("paid");


      setMessage("");
      props.handleClose();
    }
  }

  console.log(state);

  return (
    <div className={props.formClass}>
      <div
        className="modal center"
        style={{
          position: "relative",
          inset: "0px",
          border: "none",
          background: "rgb(0 0 0 / 0%)",
          overflow: "unset",
          borderRadius: "20px",
          outline: "none",
          padding: "0px",
          width: "470px",
          height: "620px",
          margin: "0px auto",
        }}
      >
        <div className="regis-popup">
          <h4 style={{ marginTop: "0px" }}>Nhập thông tin nạp tiền ký quỹ</h4>

          <div className="err-msg">{errMsg}</div>
          <br></br>
          <input
            onChange={onChange}
            autoComplete="off"
            type="text"
            name="money"
            value={props.data?.name || ""}
            disabled
            placeholder=""
          />
              <input
            onChange={onChange}
            autoComplete="off"
            type="text"
            name="money"
            value={formatPrice(props.data?.price )|| 0}
            disabled
            placeholder="Số tiền nạp"
          />
          {/* <input
            autoComplete="off"
            type="text"
            onChange={onChange}
            value = {bank_account_name}
            name="bank_account_name"
            placeholder="Tên tài khoản"
          />
          <input
            name="bank_number"
            onChange={onChange}
            type="text"
            value = {bank_number}

            placeholder="Số tài khoản"
          />
          <select name="bank_name" onChange={onChange} value={bank_name} id="">
            <option value="">-- Chọn ngân hàng --</option>

            {banks.map((v, i) => (
              <option value={v.name}>{v.name}</option>
            ))}
          </select>
          <textarea
            onChange={onChange}
            name="reason"
            value={reason}
            placeholder="Nội dung chuyển khoản"
          ></textarea> */}

          <div style={{ float: "right" }}>
            <button
              style={{ marginRight: "10px" }}
              className="button-cancel"
              onClick={props.handleClose}
            >
              Hủy bỏ
            </button>
            <button className="button-submit" onClick={handleSubmit}>
              Thực hiện
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
