import React, { useEffect, useState } from "react";
import ReceiptTab from "./ReceiptTab";
import { Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { useSelector, useDispatch } from "react-redux";
import 'react-google-flight-datepicker/dist/main.css';
import { collaboratorActions as a } from "../../../actions/collaboratorActions";
import { formatPrice, formatPriceOrContact, handleImgErr } from "../../../helper";
import PickerDate from "../../../components/PickerDate/PickerDate";
import { constants as c } from "../../../constants";
export default function ReportCollaborator(props) {

  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState("receipt");
  const [typeChart, setTypeChart] = useState("TOTAL_FINAL");
  const account = useSelector((state) => state.collaborator.account);
  const tabs = {
    receipt: <ReceiptTab orders={props.orders} />,
  };


  useEffect(() => {


    if (account.status === c.LOADING) {
      dispatch(a.getCollaboratorReport());
    }

    if (props.currentTab == "receipt") {
      setCurrentTab("receipt");
    }
    var reportData2 = props.report;
    console.log(reportData2);

  });



  function handleSelect(ranges) {
    console.log(ranges);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  };

  function changeSelect(event) {
    setTypeChart(event.target.value)
  }

  var reportData2 = props.report;
  var labels = [];
  var total_final = {
    data: [],
    label: "Tổng tiền hàng",
    backgroundColor: "#3e95cd",
    fill: false
  };
  var total_order_count = {
    data: [],
    label: "Số lượng đơn hàng",
    backgroundColor: "#ff6666",
    fill: false
  };
  var total_referral_of_customer_count = {
    data: [],
    label: "Số lượng ctv giới thiệu",
    backgroundColor: "#ffbf00",
    fill: false
  };


  if (reportData2 == null) {
    dispatch(a.getCollaboratorReport());
  } else {
    labels = reportData2.data_prime_time.charts.map((dataInTime) => dataInTime.time);
    total_final = {
      data: reportData2.data_prime_time.charts.map((dataInTime) => dataInTime.total_final),
      label: "Tổng tiền hàng",
      backgroundColor: "#3e95cd",
      fill: false
    };
    total_order_count = {
      data: reportData2.data_prime_time.charts.map((dataInTime) => dataInTime.total_order_count),
      label: "Số lượng đơn hàng",
      backgroundColor: "#ff6666",
      fill: false
    };
    total_referral_of_customer_count = {
      data: reportData2.data_prime_time.charts.map((dataInTime) => dataInTime.total_referral_of_customer_count),
      label: "Số lượng ctv giới thiệu",
      backgroundColor: "#ffbf00",
      fill: false
    };


    var reportData = {}

    if (typeChart == "TOTAL_FINAL") {
      reportData = {
        labels: labels,
        datasets: [
          total_final,

        ]
      }
    }
  }


  if (typeChart == "ORDER_COUNT") {
    reportData = {
      labels: labels,
      datasets: [
        total_order_count,
      ]
    }
  }
  if (typeChart == "CTV") {
    reportData = {
      labels: labels,
      datasets: [
        total_referral_of_customer_count
      ]
    }
  }
  function onChangeDate(query) {
    props.getReport(query)
  }
  return (

    reportData2 == null ? <div></div> : <div className="balance-tab">
      <div className="col col-7">
        <PickerDate onChangeDate={onChangeDate} />
        <div style={{ marginTop: '10px' }}>
          <p>Tổng tiền hàng: {formatPrice(reportData2?.data_prime_time?.total_final ?? 0)}</p>
          <p>Số lượng đơn: {reportData2?.data_prime_time?.total_order_count ?? 0}</p>
          <p>Số CTV giới thiệu: {reportData2?.data_prime_time?.total_referral_of_customer_count ?? 0}</p>
        </div>
      </div>
      <div className="col col-5 float-end">
        <div className="row">
          <label style={{ paddingTop: '7px' }}>Biểu đồ chi tiết</label>
          <select onChange={changeSelect} name="" id="input" class="form-control form-select" required="" style={
            { maxWidth: 280 }
          }>
            <option value="TOTAL_FINAL">Theo tổng tiền hàng</option>
            <option value="ORDER_COUNT"> Theo số lượng đơn hàng</option>
            <option value="CTV"> Theo số CTV giới thiệu</option>
          </select>
        </div>
      </div>
      <div className="col-12" style={
        { width: "auto" }
      }>

        <Bar
          data={
            reportData
          }
          options={{
            title: {
              display: true,
              text: "Báo cáo doanh số "
            },
            legend: {
              display: true,
              position: "bottom"
            }
          }}
        />

      </div>

      {/* <div className="mini-tabs">
        <div
          onClick={() => {
            setCurrentTab("receipt")
            props.setCurrentReportCollaborator("receipt")
          }}
          className={currentTab === "receipt" ? "active" : ""}
        >
          <img src="/img/receipt.png" alt="" />
          <span>Đơn hàng của bạn</span>
        </div>
    
        
      </div> */}
      {/* {tabs[currentTab]} */}

    </div>
  );
}
