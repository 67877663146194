import { useSelector, useDispatch } from "react-redux";
import Select from "../../../../components/Select";
import { appActions as appActions } from "../../../../actions/appActions";
import { constants as c } from "../../../../constants";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { agencyActions as a } from "../../../../actions/agencyAction";
import { formatNoD, formatNumber } from "../../../../helper";

export default function RechargeForm(props) {
  const dispatch = useDispatch();
  const {
    first_and_last_name,
    bank,
    branch,
    account_name,
    account_number,
    back_card,
    front_card,
    cmnd,
    issued_by,
    balance,
  } = props.account;

  const [banks, setBanks] = useState([]);
  const [errMsg, setMessage] = useState("");
  const [state, setState] = useState({
    bank_name : "", money: "", bank_account_name : "", bank_number : ""
  });

  function onChange(e) {
    var { name, value } = e.target;
    setState({ ...state, [name]: formatNumber(value) });
  }

  const {  money  } = state;

  useEffect(async () => {
    var res = await fetch(`https://api.vietqr.io/v2/banks`);
    try {
      res.json().then((res) => {
        setBanks(res.data);
      });
    } catch (error) {}
    setBanks([]);
  }, []);

  function handleSubmit() {


    if (
      bank == null || bank == "" ||
      account_name == "" || account_name == null ||
      account_number == "" || account_number == null
    ) {
      setMessage("Vui lòng cập nhật thông tin tài khoản ngân hàng");
      return;
    } 
    else if( money == "" || money == null )
    {
      setMessage("Vui lòng nhập số tiền muốn thanh toán");
      return;
    }
    else {
      dispatch(
        a.postAgencyWithDraw({
          money: money,
          bank_name: bank,
          bank_account_name: account_name,
          bank_number: account_number,
        })
      );
      dispatch(appActions.changePopup(c.MESSAGE_POPUP));
      props.setCurrentTab("paid");

      setState({
      money : ""
      });
      setMessage("")
      props.handleClose();
    }
  }

  console.log(state);

  return (
    <div className={props.formClass}>
      <div
        className="modal center"
        style={{
          position: "relative",
          inset: "0px",
          border: "none",
          background: "rgb(0 0 0 / 0%)",
          overflow: "unset",
          borderRadius: "20px",
          outline: "none",
          padding: "0px",
          width: "470px",
          height: "620px",
          margin: "0px auto",
        }}
      >
        <div className="regis-popup">
          <h4 style={{ marginTop: "0px" }}>Nhập số tiền rút</h4>

          <div className="err-msg">{errMsg}</div>
          <br></br>
          <input
            onChange={onChange}
            autoComplete="off"
            type="text"
            name="money"
            value={formatNoD(money)}
            placeholder="Số tiền rút"
          />
        
    
          <div style={{ float: "right"  , display : "flex"}}>
            <button
              style={{ marginRight: "10px" , color : "gray" }}
              className="button-cancel"
              onClick={props.handleClose}
            >
              Hủy bỏ
            </button>
            <button style={{cursor : "pointer"}} className="button-submit" onClick={handleSubmit}>
              Thực hiện
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
