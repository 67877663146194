import { constants as c } from "../constants";
const initialState = {
  account: {
    status: c.LOADING,
  },
  info: {
    status: c.LOADING,
    steps_bonus: [],
  },
  orders: {
    status: c.LOADING,
    data: [],
  },
  recharge: {
    status: c.LOADING,
    data: [],
  },
  with_draw: {
    status: c.LOADING,
    data: [],
  },
  agency_type: {
    status: c.LOADING,
    data: [],
  },
  history: {
    status: c.LOADING,
    data: [],
  },
  history_disposit: {
    status: c.LOADING,
    data: [],
  },
  bonus: {
    status: c.LOADING,
    data: [],
  },
};
export function agency(state = initialState, action) {
  switch (action.type) {
    case c.GET_AGENCY_REPORT_SUCCESS:
      return {
        ...state,
        report: {
          ...action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_AGENCY_REPORT_FAILURE:
      return {
        ...state,
        report: {
          status: c.FAILURE,
        },
      };
    case c.GET_AGENCY_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: {
          ...action.data,
          status: c.SUCCESS,
        },
      };

    case c.GET_AGENCY_ACCOUNT_FAILURE:
      return {
        ...state,
        account: {
          status: c.FAILURE,
        },
      };

    case c.GET_AGENCY_RECHARGE_SUCCESS:
      return {
        ...state,
        recharge: {
          ...action.data,
          status: c.SUCCESS,
        },
      };
    case c.RESET_AGENCY_RECHARGE_STATUS:
      return {
        ...state,
        recharge: {
          status: c.LOADING,
        },
      };

    case c.GET_AGENCY_RECHARGE_FAILURE:
      return {
        ...state,
        recharge: {
          status: c.FAILURE,
        },
      };
   
   

      case c.GET_AGENCY_TYPE_SUCCESS:
        return {
          ...state,
          agency_type: {
            data : action.data,
            status: c.SUCCESS,
          },
        };
      case c.RESET_AGENCY_TYPE_STATUS:
        return {
          ...state,
          agency_type: {
            status: c.LOADING,
          },
        };
  
      case c.GET_AGENCY_TYPE_FAILURE:
        return {
          ...state,
          agency_type: {
            status: c.FAILURE,
          },
        };
     
     




      case c.GET_AGENCY_WITH_DRAW_SUCCESS:
        return {
          ...state,
          with_draw: {
            ...action.data,
            status: c.SUCCESS,
          },
        };
      case c.RESET_AGENCY_WITH_DRAW_STATUS:
        return {
          ...state,
          with_draw: {
            status: c.LOADING,
          },
        };
  
      case c.GET_AGENCY_WITH_DRAW_FAILURE:
        return {
          ...state,
          with_draw: {
            status: c.FAILURE,
          },
        };
     


      case c.GET_AGENCY_INFO_SUCCESS:
      return {
        ...state,
        info: {
          ...action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_AGENCY_INFO_FAILURE:
      return {
        ...state,
        info: {
          status: c.FAILURE,
          steps_bonus: [],
        },
      };
    case c.GET_SHARED_ORDERS_SUCCESS:
      return {
        ...state,
        orders: {
          ...action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_SHARED_ORDERS_FAILURE:
      return {
        ...state,
        order: {
          status: c.FAILURE,
          data: [],
        },
      };
    case c.GET_BALANCE_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          ...action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_BALANCE_HISTORY_FAILURE:
      return {
        ...state,
        history: {
          status: c.FAILURE,
          data: [],
        },
      };
    

      case c.GET_BALANCE_HISTORY_DISPOSIT_SUCCESS:
        return {
          ...state,
          history_disposit: {
            ...action.data,
            status: c.SUCCESS,
          },
        };
      case c.GET_BALANCE_HISTORY_DISPOSIT_FAILURE:
        return {
          ...state,
          history_disposit: {
            status: c.FAILURE,
            data: [],
          },
        };
      

      case c.GET_BONUS_HISTORY_SUCCESS:
      return {
        ...state,
        bonus: {
          ...action.data,
          status: c.SUCCESS,
        },
      };
    case c.GET_BONUS_HISTORY_FAILURE:
      return {
        ...state,
        bonus: {
          status: c.FAILURE,
          data: [],
        },
      };
    default:
      return state;
  }
}
