import React, { useEffect, useRef , useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../actions/appActions";
import { userActions } from "../../actions/userActions";
import "./style.css";


import { useLocation } from 'react-router-dom';
// import { collaboratorActions } from "../../actions/collaboratorActions";
export default function Header_1(props) {
    const dispatch = useDispatch();
    const [currentActive, setCurrentActive] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const wrapperRef = useRef(null);
    const tokenInfo = useSelector((state) => state.user.tokenInfo);
    const appTheme = useSelector((state) => state.app.appTheme);
    const badges = useSelector((state) => state.user.badges);

    const notify = useSelector((state) => state.user.notify);

    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        const searchParams = new URLSearchParams(location.search);
        setSearchValue(searchParams.get("search") ?? "");
    }, [location]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) 
        setCurrentActive("");

        console.log(wrapperRef)
    }



    function toggleMenu(selector) {
        const menuToggle = document.querySelector(`${selector} .menu`);
        menuToggle.classList.toggle("active");
    }
    function handleToggleActive(type) {
        if (currentActive === type) {
            setCurrentActive("");
            return;
        }
        if(currentActive == "")
        {
            dispatch(userActions.readAllNoti());

        }
        setCurrentActive(type);
    }

    function handleNotificationClick(v) {
        handleToggleActive("notify");
        if (v.type === "NEW_MESSAGE") {
            dispatch(appActions.setChatStatus("active"));
            return;
        }
        let arr = v.title.split(" ");
        // let orderID = arr[arr.length - 1];
        let orderID = v.references_value
        if(v.type === "NEW_POST")
        {
            window.location.href = `/tin-tuc/${orderID}`;
            return;
        }
        if(orderID)
        window.location.href = `/don-hang/${orderID}`;
    }

    return (
        <React.Fragment>
            {!tokenInfo ? (
                ""
            ) : (
                <div className="notify header-dropdown" style={props.style && props.style?.header_dropdown} ref={wrapperRef}>
                    <div
                        onClick={() => handleToggleActive("notify")}
                        style = {{
                            "margin-right": 0

                        }}
                        className="header-btn row"
                    >
                        {props.useIcon === true ? <i class="fas fa-bell" style={{
                            fontSize: props.fontSize ? props.fontSize : "27px",
                            color: props.colorIcon ?? "grey"
                        }}></i> : <img src="/img/bell (1).png" alt="" />}

                        <div style={{ width: props.hideTitle === true ? "30px"  : "90px" }}>
                            {badges.notification_unread === 0 ? (
                                <div style={{ margin: 18 }}>{null}</div>
                            ) : (
                                <div className="number">{badges.notification_unread }</div>

                            )}

                            {props.hideTitle !== true && <div className="title" style={{ color: props.colorIcon ?? "grey" }}
                            >Thông báo</div>}

                        </div>
                    </div>
                    <div
                        className={
                            currentActive === "notify"
                                ? "menu dropdown active"
                                : "menu dropdown"
                        }
                    >
                        <div style={{ background: appTheme.color_main_1 }} className="title">
                            <h3 style={{ color: "white" }}>Thông báo mới</h3>

                        </div>
                        <div className="column hide-scroll">
                            {notify.data.map((v, i) => (
                                <button
                                    key={i}
                                    className="item"
                                    style={{ width: "100%", float: "left" }}
                                    onClick={() => handleNotificationClick(v)}
                                >
                                    <div className="title" style={{ textAlign: "left" }}>
                                        {v.type == "NEW_POST" ? "Có bài viết mới" : v.title}
                                    </div>
                                    <div className="content" style={{ textAlign: "left" }}>
                                        {v.content}
                                    </div>
                                    <div className="date">
                                        {`${v.created_at.split(" ")[0]}  ${v.created_at
                                            .split(" ")[1]
                                            .slice(0, 5)}`}
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
