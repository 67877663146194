import React, { useEffect, useState } from "react";

import InfoTab from "./InfoTab";
import Payment1Tab from "./Payment1Tab";

import PaidTab from "./PaidTab";

export default function StatisticTab(props) {
  const [currentTab, setCurrentTab] = useState("info");


  const tabs = {
    info: (
      <InfoTab
        profile={props.profile}
        info={props.info}
        account={props.account}
        setCurrentTab={setCurrentTab}
        agency_type = {props.agency_type}
        setCurrentStatisticTab={props.setCurrentStatisticTab}
      />
    ),
    payment: (
      <Payment1Tab
        info={props.info}
        account={props.account}
        onRequestPayment={props.requestPayment}
        setCurrentTab={setCurrentTab}

      />
    ),
    paid: <PaidTab />,
  };

  useEffect(() => {
    if (props.currentTab == "balance") {
      setCurrentTab("balance");
    }

    if (props.currentTab == "receipt") {
      setCurrentTab("receipt");
    }

    if (props.currentTab == "bonus") {
      setCurrentTab("bonus");
    }
  });

  return (
    <div className="statistic-tab">
      <div
        className="mini-tabs"
        style={{
          "min-width": "220px",
          "max-width": "220px",
        }}
      >
       
        <div
          onClick={() => {
            setCurrentTab("info");
            props.setCurrentStatisticTab("info");
          }}
          className={currentTab === "info" ? "active" : ""}
        >
          <img src="/img/receipt.png" alt="" />
          <span>Nạp tiền</span>
        </div>

        <div
          onClick={() => {
            setCurrentTab("payment");
            props.setCurrentStatisticTab("payment");
          }}
          className={currentTab === "payment" ? "active" : ""}
        >
          <img src="/img/wallet.png" alt="" />
          <span>Thanh toán</span>
        </div>

        <div
          onClick={() => {
            setCurrentTab("paid");
            props.setCurrentStatisticTab("paid");
          }}
          className={currentTab === "paid" ? "active" : ""}
        >
          <img src="/img/money-flow.png" alt="" />
          <span>Lịch sử Nạp / Rút tiền</span>
        </div>


      </div>
      {tabs[currentTab]}
    </div>
  );
}
