import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import InfoTab from "./child/InfoTab";
import PaymentTab from "./child/PaymentTab";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import StopWorking from "./child/StopWorking";
import StatisticTab from "./child/StatisticTab";
import PaidOverviewTab from "./child/PaidOverviewTab";
import Payment1Tab from "./child/Payment1Tab";

import { constants as c } from "../../constants";
import PageLoading from "../../components/PageLoading";
import { collaboratorActions as a } from "../../actions/collaboratorActions";
import "./style.css"
import { agencyActions as b } from "../../actions/agencyAction";

function AgencyPage(props) {
  const dispatch = useDispatch();
  const report = useSelector((state) => state.agency.report);
  const account = useSelector((state) => state.agency.account);
  const history = useSelector((state) => state.agency.history);
  const agency_type = useSelector((state) => state.agency.agency_type);

  const history_disposit = useSelector((state) => state.agency.history_disposit);

  const bonus = useSelector((state) => state.agency.bonus);

  const orders = useSelector((state) => state.agency.orders);
  const info = useSelector((state) => state.collaborator.info);
  const profile = useSelector((state) => state.user.profile);
  const badges = useSelector((state) => state.user.badges);
  const [currentTab, setCurrentTab] = useState("info");
  const [currentStatisticTab, setCurrentStatisticTab] = useState("receipt");

  function requestPayment() {
    dispatch(a.requestPayment());
  }
  const tabs = {
    // info: (
    //   <InfoTab
    //   profile = {profile}
    //     info={info}
    //     account = {account}
    //     setCurrentTab={setCurrentTab}
    //     setCurrentStatisticTab={setCurrentStatisticTab}
    //   />
    // ),
    
    info: (
      <PaidOverviewTab
      profile = {profile}
        info={info}
        account = {account}
        agency_type = {agency_type}
        setCurrentTab={setCurrentTab}
        setCurrentStatisticTab={setCurrentStatisticTab}
        onRequestPayment={requestPayment}

        
      />
    ),
    

    statistic: (
      <StatisticTab
        orders={orders}
        report={report}
        history={history}
        account={account}

        history_disposit = {history_disposit}
        bonus={bonus}
        currentTab={currentStatisticTab}
        setCurrentStatisticTab={setCurrentStatisticTab2}
        getReport={getReport}
      />
    ),
  };
  function setCurrentStatisticTab2(va) {
    setCurrentStatisticTab(va);
  }
  useEffect(() => {
    console.log(profile, badges)
    window.scrollTo({ top: 0 });
    document.title = "Đại lý";
    if (account.status === c.LOADING) {
      dispatch(b.getAccountInfo());
      dispatch(a.getCollaboratorReport());
    }
    if (info.status === c.LOADING) {
      // dispatch(a.getInfo());
    }
    if (orders.status === c.LOADING) {
      dispatch(b.getSharedOrder(""));
    }
    if (history.status === c.LOADING) {
      dispatch(b.getBalanceHistory());
    }
    if (history_disposit.status === c.LOADING) {
      dispatch(b.getBalanceHistoryDisposit());
    }
    if (agency_type.status === c.LOADING) {
      dispatch(b.getAgencyType());
    }
    
    if (bonus.status === c.LOADING) {
      dispatch(b.getBonusHistory());
    }
  }, []);
  function requestPayment() {
    dispatch(a.requestPayment());
  }
  function getReport(query) {
    dispatch(b.getAgencyReport(query));
  }

  console.log(account);
  return (
    <React.Fragment>
      {/* <Header /> */}
      {
        account.status === c.SUCCESS ?
          (
            (
              profile.is_agency && badges.status_agency === 1) ?
              (
                <React.Fragment>
                <div className="collaborator-page agency-page">
                  <div className="container">
                    <div className="row tabs">
                      <div
                        onClick={() => setCurrentTab("info")}
                        className={currentTab === "info" ? "active" : ""}
                      >
                        Thông tin
                      </div>
                      <div
                        onClick={() => setCurrentTab("statistic")}
                        className={currentTab === "statistic" ? "active" : ""}
                        style={{ marginLeft: "1.5em", marginRight: "1.5em" }}
                      >
                        Thống kê
                      </div>
                      {/* <div
                        onClick={() => setCurrentTab("payment")}
                        className={currentTab === "payment" ? "active" : ""}
                      >
                        Thanh toán
                      </div> */}
                    </div>
                    {tabs[currentTab]}
                  </div>
                </div>
                      <Footer />
                      </React.Fragment>
              ) :
              (
                <React.Fragment>
                <div className="collaborator-page">
                  <StopWorking />
                </div>
                      <Footer />
                      </React.Fragment>
              )
          ) :
          (
           null
          )
      }
  
    </React.Fragment>
  );
}
export { AgencyPage };
