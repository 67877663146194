import { constants as c } from "../constants";
import { agencyServices as s } from "../services/agencyServices";
import { userActions } from "./userActions";
import { appActions } from "./appActions";

function getAgencyReport(query) {
  return (dispatch) => {
    s.getAgencyReport(query).then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch(success(res.data));
        return;
      }
      dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_AGENCY_REPORT_SUCCESS, data };
  }
  function failure(code, msg) {
    return { type: c.GET_AGENCY_REPORT_SUCCESS, code, msg };
  }
}
function getAccountInfo() {
  return (dispatch) => {
    s.getAccountInfo().then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch(success(res.data));
        return;
      }
      dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_AGENCY_ACCOUNT_SUCCESS, data };
  }
  function failure(code, msg) {
    return { type: c.GET_AGENCY_ACCOUNT_FAILURE, code, msg };
  }
}
function getInfo() {
  return (dispatch) => {
    s.getInfo().then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch(success(res.data));
        return;
      }
      dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_AGENCY_INFO_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.GET_AGENCY_INFO_FAILURE, code, message };
  }
}
function getSharedOrder(query) {
  return (dispatch) => {
    s.getSharedOrder(query).then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch(success(res.data));
        return;
      }
      dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_SHARED_ORDERS_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.GET_SHARED_ORDERS_FAILURE, code, message };
  }
}
function getBalanceHistory() {
  return (dispatch) => {
    s.getBalanceHistory().then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch(success(res.data));
        return;
      }
      dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_BALANCE_HISTORY_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.GET_BALANCE_HISTORY_FAILURE, code, message };
  }
}
function getBalanceHistoryDisposit() {
  return (dispatch) => {
    s.getBalanceHistoryDisposit().then((res) => {
      console.log("s.getBalanceHistoryDisposit ~ res", res);
      if (res.code === 200 || res.code === 201) {
        dispatch(success(res.data));
        return;
      }
      dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_BALANCE_HISTORY_DISPOSIT_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.GET_BALANCE_HISTORY_DISPOSIT_FAILURE, code, message };
  }
}

function getBonusHistory() {
  return (dispatch) => {
    s.getBonusHistory().then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch(success(res.data));
        return;
      }
      dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_BONUS_HISTORY_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.GET_BONUS_HISTORY_FAILURE, code, message };
  }
}
function getAgencyRechage() {
  return (dispatch) => {
    s.getAgencyRechage().then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch(success(res.data));
        return;
      }
      dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_AGENCY_RECHARGE_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.GET_AGENCY_RECHARGE_FAILURE, code, message };
  }
}

function getAgencyWithDraw() {
  return (dispatch) => {
    s.getAgencyWithDraw().then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch(success(res.data));
        return;
      }
      dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_AGENCY_WITH_DRAW_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.GET_AGENCY_WITH_DRAW_FAILURE, code, message };
  }
}
function getAgencyType() {
  return (dispatch) => {
    s.getAgencyType().then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch(success(res.data));
        return;
      }
      dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.GET_AGENCY_TYPE_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.GET_AGENCY_TYPE_FAILURE, code, message };
  }
}
function requestPayment() {
  return (dispatch) => {
    s.requestPayment().then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch(success(res.data));
        dispatch({
          type: c.CHANGE_POPUP,
          popupType: c.AUTOHIDE_POPUP,
          messageInfo: "Gửi yêu cầu thanh toán thành công",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        return;
      }
      dispatch({
        type: c.CHANGE_POPUP,
        popupType: c.AUTOHIDE_POPUP,
        messageInfo: res.msg,
      });
      dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.REQUEST_PAYMENT_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.REQUEST_PAYMENT_FAILURE, code, message };
  }
}
function updateInfo(info) {
  return (dispatch) => {
    s.updateInfo(info).then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch(success(res.data));
        dispatch({
          type: c.CHANGE_POPUP,
          popupType: c.AUTOHIDE_POPUP,
          messageInfo: "Cập nhật thông tin thành công",
        });
        setTimeout(() => {
          s.getAccountInfo().then((res) => {
            if (res.code === 200 || res.code === 201) {
              dispatch({ type: c.GET_AGENCY_ACCOUNT_SUCCESS, data: res.data });
              return;
            }
            dispatch(failure(res.code, res.msg));
          });
        }, 1);
        return;
      }

      dispatch({
        type: c.CHANGE_POPUP,
        popupType: c.AUTOHIDE_POPUP,
        messageInfo: res.msg,
      });
      dispatch(failure(res.code, res.msg));
    });
  };
  function success(data) {
    return { type: c.UPDATE_AGENCY_INFO_SUCCESS, data };
  }
  function failure(code, message) {
    return { type: c.UPDATE_INFO_AGENCY_FAILURE, code, message };
  }
}
function regisAgency() {
  return (dispatch) => {
    s.regisAgency(true).then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch({
          type: c.CHANGE_POPUP,
          popupType: c.AUTOHIDE_POPUP,
          messageInfo:
            "Đăng ký Đại lý thành công vui lòng chờ phản hồi từ cửa hàng",
        });
        dispatch(userActions.getUserProfile());
        return;
      }
      dispatch({
        type: c.CHANGE_POPUP,
        popupType: c.AUTOHIDE_POPUP,
        messageInfo: res.msg,
      });
    });
  };
}
function cancelAgency() {
  return (dispatch) => {
    s.regisAgency(false).then((res) => {
      if (res.code === 200 || res.code === 201) {
        dispatch({
          type: c.CHANGE_POPUP,
          popupType: c.AUTOHIDE_POPUP,
          messageInfo: "Hủy CTV thành công vui lòng chờ phản hồi từ cửa hàng",
        });
        dispatch(userActions.getUserProfile());
        return;
      }
      dispatch({
        type: c.CHANGE_POPUP,
        popupType: c.AUTOHIDE_POPUP,
        messageInfo: res.msg,
      });
    });
  };
}

function postAgencyRechage(info) {
  return (dispatch) => {
    s.postAgencyRechage(info).then((res) => {
      if (res.code === 200) {
        dispatch({
          type: c.ADD_RECHARGE_SUCCESS,
          message:
            "Thực hiện giao dịch thành công! Vui lòng chờ phản hồi từ Shop",
        });
        dispatch(success(res.data));
      } else {
        dispatch(failure(res));
      }
    });
  };
  function success() {
    return {
      type: c.RESET_AGENCY_RECHARGE_STATUS,
    };
  }
  function failure(res) {
    return {
      type: c.ADD_RECHARGE_FAILURE,
      message: res.msg,
    };
  }
}

function postAgencyWithDraw(info) {
  return (dispatch) => {
    s.postAgencyWithDraw(info).then((res) => {
      if (res.code === 200) {
        dispatch({
          type: c.ADD_RECHARGE_SUCCESS,
          message:
            "Thực hiện giao dịch thành công! Vui lòng chờ phản hồi từ Shop",
        });
        dispatch(success(res.data));
      } else {
        dispatch(failure(res));
      }
    });
  };
  function success() {
    return {
      type: c.RESET_AGENCY_WITH_DRAW_STATUS,
    };
  }
  function failure(res) {
    return {
      type: c.ADD_RECHARGE_FAILURE,
      message: res.msg,
    };
  }
}
export const agencyActions = {
  getInfo,
  updateInfo,
  getSharedOrder,
  getAccountInfo,
  requestPayment,
  getBonusHistory,
  getBalanceHistory,
  regisAgency,
  cancelAgency,
  getAgencyReport,
  getAgencyRechage,
  postAgencyRechage,
  getAgencyWithDraw,
  postAgencyWithDraw,
  getBalanceHistoryDisposit,
  getAgencyType,
};
